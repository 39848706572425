import React from 'react'
import { Add, Delete, Photo, } from '@mui/icons-material'
import { Box, Button, Checkbox, Grid, IconButton, List, ListItem, MenuItem, TextField } from '@mui/material'
import uuid from 'react-uuid'
import { FieldArray, Form, Formik, getIn, } from "formik"
import { useParams, } from 'react-router-dom'
import { handleCorrectAnswersChange, times } from '../utils/constants'
import { multipleChoiceValidationSchema, validationSchema } from '../utils/validation'
import axios from 'axios'
import { AuthContext } from '../contexts/AuthContext'
import { addPollImageUrl, addPollUrl, getSpecificPollResponseUrl, publicBaseUrl, updatePollUrl } from '../utils/urls'

export default function BuildPolls({
    pollType,
    action,
    selected,
    setSelected,
    handleRefresh,
    handleCloseCreatePolls,
    folders,
    folderName,
}) {
    const { currentUser, } = React.useContext(AuthContext)
    const [folder, setFolder] = React.useState(folderName)
    const [time, setTime] = React.useState(1)
    const { courseId, folderId } = useParams()
    const [options, setOptions] = React.useState([])
    const [correctAnswers, setCorrectAnswers] = React.useState([])
    const [selectedImage, setSelecedimage] = React.useState(null)
    const [imageurl, setImageUrl] = React.useState(null)
    const [close, setClose] = React.useState(false)

    React.useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await axios.post(
                    getSpecificPollResponseUrl,
                    {
                        "poll_id": selected[0]?.id,
                        "user_id": currentUser?.id,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                            'real-time-token-auth': currentUser?.token,
                        },
                    }
                )
                if (response.data.error === false) {
                    const newOptions = []
                    const newCorrectAnswers = []
                    for (let i = 0; i < response.data.data.poll_options.length; i++) {
                        if (response.data.data.poll_options[i].correct === true) {
                            newCorrectAnswers.push(i)
                        }
                        newOptions.push(response.data.data.poll_options[i].option)
                    }
                    setCorrectAnswers(newCorrectAnswers)
                    setOptions(newOptions)
                }
            } catch (error) {
                setOptions([])
            }
        }

        action === "update" && fetchOptions()
    }, [selected, currentUser, action])

    React.useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage))
        }
    }, [selectedImage, selected])

    const handleChangeFolder = (event) => {
        setFolder(event.target.value)
    }

    const handleChangeTime = (event) => {
        setTime(event.target.value)
    }

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            setSelecedimage(e.target.files[0])
        }
    }

    const deleteUploadFile = () => {
        if (action === "update" && selected[0]?.file_path !== "") {
            const newArray = [
                ...selected.slice(0, 0),
                {
                    ...selected[0],
                    file_path: ""
                },
                ...selected.slice(0 + 1)
            ]
            setSelected(newArray)
        }
        setSelecedimage(null)
        setImageUrl(null)
    }

    return (
        <List>
            <Formik
                enableReinitialize
                initialValues={{
                    question: action === "update" ? selected[0]?.poll : "",
                    options: action === "update" ? options : [""],
                }}
                validationSchema={
                    pollType === "multipleChoices" ?
                        multipleChoiceValidationSchema :
                        validationSchema
                }
                onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
                    setSubmitting(true)
                    // console.log("onSubmit", JSON.stringify(values, null, 2))

                    const formattedOptions = values.options.map((option, index) => ({
                        option,
                        correct: correctAnswers.includes(index),
                    }))
                    try {
                        const response = await axios.post(
                            action === "create" ? addPollUrl : updatePollUrl,
                            action === "create" ? {
                                "user_id": currentUser?.id,
                                "poll": values.question,
                                "poll_type": pollType,
                                "folder_id": folder ? folder : folderId,
                                "course_code": courseId,
                                "times_to_respond": time,
                                "data": formattedOptions,
                            } : {
                                "poll_id": selected.length === 1 && selected[0]?.id,
                                "user_id": currentUser?.id,
                                "poll": values.question,
                                "data": formattedOptions,
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                                    'real-time-token-auth': currentUser?.token,
                                },
                            }
                        )
                        if (response.data.error === false) {
                            if (selectedImage) {
                                const formData = new FormData()
                                formData.append('image', selectedImage)
                                formData.append('poll_id', action === "create" ? response.data.data.id : selected[0]?.id)
                                formData.append('user_id', currentUser?.id)
                                const uploadResponse = await axios.post(
                                    addPollImageUrl,
                                    formData,
                                    {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                                            'real-time-token-auth': currentUser?.token,
                                        },
                                    }
                                )
                                if (uploadResponse.data.error === false) {
                                    handleRefresh()
                                    resetForm()
                                    setSubmitting(false)
                                    if (close) {
                                        handleCloseCreatePolls()
                                    }
                                }
                            } else {
                                handleRefresh()
                                resetForm()
                                setSubmitting(false)
                                if (close) {
                                    handleCloseCreatePolls()
                                }
                            }
                        }
                    } catch (error) {
                        setSubmitting(false)
                    }

                }}
            >
                {({ isSubmitting, values, touched, errors, handleChange, handleBlur }) => (
                    <Form noValidate autoComplete="off">
                        <ListItem
                            disablePadding
                            sx={{
                                backgroundColor: '#F3F4F6',
                                mt: 3,
                            }}
                        >
                            <TextField
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": {
                                            border: "none"
                                        }
                                    }
                                }}
                                margin="dense"
                                variant="outlined"
                                label="Question"
                                fullWidth
                                name="question"
                                value={values.question}
                                required
                                helperText={touched.question && errors.question}
                                error={Boolean(touched.question && errors.question)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {(pollType !== "questionAndAnswer" && imageurl === null && action === "create") ||
                                (pollType !== "questionAndAnswer" && imageurl === null && selected[0]?.file_path === "" && action === "update") ?
                                <Box
                                    edge="end"
                                    sx={{
                                        mx: 1,
                                    }}
                                >
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                    >
                                        <input
                                            hidden
                                            accept="image/*"
                                            type="file"
                                            onChange={readUploadFile}
                                        />
                                        <Photo />
                                    </IconButton>
                                </Box> : null
                            }
                            {(pollType !== "questionAndAnswer" && imageurl && selectedImage) ||
                                (pollType !== "questionAndAnswer" && action === "update" && selected[0]?.file_path !== "") ?
                                <Box
                                    edge="end"
                                    sx={{
                                        mx: 1,
                                        width: "10%",
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        component='img'
                                        sx={{
                                            width: "50%",
                                        }}
                                        alt={selectedImage ? selectedImage.name : "Poll image"}
                                        src={selectedImage ? imageurl : publicBaseUrl + selected[0]?.file_path}
                                    />
                                    <IconButton
                                        onClick={deleteUploadFile}
                                        color="primary"
                                        aria-label="delete picture"
                                        component="label"
                                    >
                                        <Delete />
                                    </IconButton>
                                </Box> : null
                            }
                        </ListItem>
                        <FieldArray name="options">
                            {({ push, remove, }) => (
                                <>
                                    {values.options.map((opt, index) => {
                                        const option = `options[${index}]`
                                        const touchedOption = getIn(touched, option)
                                        const errorOption = getIn(errors, option)

                                        return (
                                            <div key={index}>
                                                {pollType === "multipleChoices" &&
                                                    <ListItem
                                                        disablePadding
                                                        sx={{
                                                            backgroundColor: '#F3F4F6',
                                                            mt: 3,
                                                        }}
                                                    >
                                                        <Box
                                                            edge="start"
                                                            sx={{
                                                                mx: 1,
                                                            }}
                                                        >
                                                            <Checkbox
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 28
                                                                    }
                                                                }}
                                                                checked={correctAnswers.includes(index)}
                                                                onChange={(event) =>
                                                                    handleCorrectAnswersChange(
                                                                        index,
                                                                        event.target.checked,
                                                                        correctAnswers,
                                                                        setCorrectAnswers
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                        <TextField
                                                            sx={{
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {
                                                                        border: "none"
                                                                    }
                                                                }
                                                            }}
                                                            margin="dense"
                                                            variant="outlined"
                                                            label="Option"
                                                            fullWidth
                                                            name={option}
                                                            value={opt}
                                                            required
                                                            helperText={touchedOption && errorOption ? errorOption : ""}
                                                            error={Boolean(touchedOption && errorOption)}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <Box
                                                            edge="end"
                                                            sx={{
                                                                mx: 1,
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={() => remove(index)}
                                                                color="primary"
                                                                aria-label="delete option"
                                                                component="label"
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </Box>
                                                    </ListItem>
                                                }
                                                {
                                                    pollType === "wordCloud" &&
                                                    <ListItem
                                                        disablePadding
                                                        sx={{
                                                            backgroundColor: '#F3F4F6',
                                                            mt: 3,
                                                        }}
                                                    >
                                                        <TextField
                                                            sx={{
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {
                                                                        border: "none"
                                                                    }
                                                                }
                                                            }}
                                                            margin="dense"
                                                            label="Answer is optional"
                                                            fullWidth
                                                            variant="outlined"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name={option}
                                                            value={opt}
                                                        />
                                                    </ListItem>
                                                }
                                            </div>
                                        )
                                    })}
                                    <Grid container spacing={3} sx={{ mt: 2 }}>
                                        {pollType === "multipleChoices" &&
                                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<Add />}
                                                    size="medium"
                                                    onClick={() => push("")}
                                                    disabled={values.options.length >= 5}
                                                >
                                                    Add option
                                                </Button>
                                            </Grid>
                                        }
                                        {action === "create" &&
                                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                                <TextField
                                                    id="outlined-select-currency"
                                                    select
                                                    label="Assign activity"
                                                    value={folder}
                                                    onChange={handleChangeFolder}
                                                    size='small'
                                                    fullWidth
                                                >
                                                    {folders.map((option) => (
                                                        <MenuItem
                                                            key={uuid()}
                                                            value={option.id}
                                                        >
                                                            {option.folder_name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        }
                                        {pollType !== "multipleChoices" && action === "create" &&
                                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                                <TextField
                                                    id="outlined-select-currency"
                                                    select
                                                    label="How many times student to respond"
                                                    value={time}
                                                    onChange={handleChangeTime}
                                                    size='small'
                                                    fullWidth
                                                >
                                                    {times.map((option) => (
                                                        <MenuItem
                                                            key={uuid()}
                                                            value={option.value}
                                                        >
                                                            {option.value}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        }
                                        {action === "create" &&
                                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                                <Button
                                                    variant="outlined"
                                                    size="medium"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                >
                                                    Add another activity
                                                </Button>
                                            </Grid>
                                        }
                                        {action === "create" &&
                                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    onClick={() => { setClose(true) }}
                                                >
                                                    Create
                                                </Button>
                                            </Grid>
                                        }
                                        {action === "update" &&
                                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    sx={{ mb: 1 }}
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    onClick={() => { setClose(true) }}
                                                >
                                                    Update
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </>
                            )}
                        </FieldArray>
                    </Form>
                )}
            </Formik>
        </List>
    )
}