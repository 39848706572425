import { DownloadForOfflineOutlined } from '@mui/icons-material'
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { getSpecificPollResponseHistoryUrl } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'
import { postAPIRequest } from '../services/postAPIRequest'
import DownloadPopOver from './DownloadPopOver'
import { seeResponsesHeadCells } from '../utils/tableHeaders'
import { handleExport } from '../utils/constants'

export default function SeeResponses({ selected, }) {
  const { currentUser } = React.useContext(AuthContext)
  const [poll, setPoll] = React.useState({})
  const [searchTerm, setSearchTerm] = React.useState("")
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [isLoading, setIsLoading] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  React.useEffect(() => {
    postAPIRequest(
      getSpecificPollResponseHistoryUrl,
      {
        "poll_id": selected[0]?.id,
        "user_id": currentUser?.id,
      },
      setPoll,
      setIsLoading,
    )
  }, [currentUser, selected])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }
  return (
    <>
      <DownloadPopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleExport={handleExport}
        headCells={seeResponsesHeadCells}
        rows={poll?.poll_responses}
        from={"responses"}
        isLoading={isLoading}
      />
      <Typography
        variant='h4'
      >
        Response history
      </Typography>
      <Typography
        variant='h5'
        sx={{
          pt: 2,
        }}
      >
        {poll?.poll}
      </Typography>
      {isLoading === false && poll?.poll_type === "multipleChoices" &&
        <>
          <Typography
            variant='h6'
            sx={{
              py: 2,
            }}
          >
            Summary
          </Typography>
          <TableContainer sx={{ width: "30vw" }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Response</TableCell>
                  <TableCell align="right">Correct Answer</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {poll?.poll_options?.map((option, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {option.option}
                    </TableCell>
                    <TableCell align="right">{option.correct ? "yes" : "no"}</TableCell>
                    <TableCell align="right">
                      {poll?.poll_responses
                        .filter(item => item.response === option.option)
                        .length
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
      <Typography
        variant='h6'
        sx={{
          py: 2,
        }}
      >
        Individual poll
      </Typography>
      <Paper sx={{ width: '100%', }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {poll?.poll_type === "multipleChoices" && "Multiple choice question"}
            {poll?.poll_type === "wordCloud" && "Word cloud question"}
            {poll?.poll_type === "questionAndAnswer" && "Question and answer question"}
          </Typography>

          <TextField
            id="search"
            label="Search"
            variant="outlined"
            size='small'
            color="primary"
            onChange={handleSearch}
          />

          <Tooltip title="Download excel">
            <IconButton
              onClick={(event) => {
                handleClick(event)
              }}
            >
              <DownloadForOfflineOutlined />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {seeResponsesHeadCells.map((headCell) => (
                  <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {poll?.poll_responses?.filter(item =>
                Object.values(item).some(value =>
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((response, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ textDecoration: response.status ? 'line-through' : 'none' }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {response.response}
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      {response.user_id}
                    </TableCell>
                    {poll?.poll_type === "wordCloud" &&
                      <TableCell
                        align="left"
                      >
                        {poll?.poll_options
                          .some(item => item.option === response.response) ?
                          "yes" : "no"
                        }
                      </TableCell>
                    }
                    {poll?.poll_type === "multipleChoices" &&
                      <TableCell
                        align="left"
                      >
                        {poll?.poll_options
                          .filter(item => item.correct === true)
                          .some(item => item.option === response.response) ?
                          "yes" : "no"
                        }
                      </TableCell>
                    }
                    {poll?.poll_type === "questionAndAnswer" &&
                      <TableCell
                        align="left"
                      ></TableCell>
                    }
                    <TableCell
                      align="left"
                    >
                      {response.created_at}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {poll?.poll_options?.length === 0 &&
          <Typography
            sx={{ my: 3, }}
            align='center'
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            No poll
          </Typography>
        }
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={poll?.poll_responses ? poll?.poll_responses?.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}