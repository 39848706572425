import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Check, Cloud, ThumbUp } from '@mui/icons-material'
import BuildPolls from './BuildPolls'
import { Box } from '@mui/material'

function TabPanel({
    children,
    value,
    index,
}) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function BasicTabs({
    action,
    selected,
    setSelected,
    handleRefresh,
    handleCloseCreatePolls,
    folders,
}) {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: "#F3F4F6",
            }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="basic tabs example"

                >
                    <Tab icon={<Check />} label="Multiple choices" {...a11yProps(0)} />
                    <Tab icon={<Cloud />} label="Word cloud" {...a11yProps(1)} />
                    <Tab icon={<ThumbUp />} label="Question & Answer" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel
                value={value}
                index={0}
            >
                <BuildPolls
                    pollType={"multipleChoices"}
                    action={action}
                    selected={selected}
                    setSelected={setSelected}
                    handleRefresh={handleRefresh}
                    handleCloseCreatePolls={handleCloseCreatePolls}
                    folders={folders}
                />
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
            >
                <BuildPolls
                    pollType={"wordCloud"}
                    action={action}
                    selected={selected}
                    setSelected={setSelected}
                    handleRefresh={handleRefresh}
                    handleCloseCreatePolls={handleCloseCreatePolls}
                    folders={folders}
                />
            </TabPanel>
            <TabPanel
                value={value}
                index={2}
            >
                <BuildPolls
                    pollType={"questionAndAnswer"}
                    action={action}
                    selected={selected}
                    setSelected={setSelected}
                    handleRefresh={handleRefresh}
                    handleCloseCreatePolls={handleCloseCreatePolls}
                    folders={folders}
                />
            </TabPanel>
        </Box>
    )
}