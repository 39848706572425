import * as React from 'react'
import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import { FileDownloadOutlined, } from "@mui/icons-material"
import { IconButton, TextField, Toolbar, Tooltip, Typography } from "@mui/material"
import DownloadPopOver from './DownloadPopOver'

function EnhancedTableToolbar({
  handleSearch,
  handleExport,
  label,
  headCells,
  rows,
  from,
  isLoading,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        bgcolor: (theme) =>
          alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {label}
      </Typography>

      <TextField
        id="search"
        label={'search'}
        variant="outlined"
        size='small'
        color="primary"
        onChange={handleSearch}
      />

      <Tooltip title={'download excel'}>
        <IconButton
          onClick={(event) => {
            handleClick(event)
          }}
        >
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>

      <DownloadPopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleExport={handleExport}
        headCells={headCells}
        rows={rows}
        from={from}
        isLoading={isLoading}
      />
    </Toolbar>
  )
}

export default EnhancedTableToolbar

EnhancedTableToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
}