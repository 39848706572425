import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Container } from '@mui/material';

export default function CustomSkeleton() {
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        height: "calc(100vh - 200px)",
        // backgroundImage: `url(${Image})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // backgroundPosition: "center",
      }}
    >
      <Box sx={{ width: "100%", }}>
        <Skeleton animation="wave" sx={{ height: 140 }} />
        <Skeleton animation="wave" sx={{ height: 80 }} />
        <Skeleton animation="wave" sx={{ height: 40 }} />
        <Skeleton animation="wave" sx={{ height: 40 }} />
        <Skeleton animation="wave" sx={{ height: 40 }} />
        <Skeleton animation="wave" sx={{ height: 40 }} />
        <Skeleton animation="wave" sx={{ height: 40 }} />
        <Skeleton animation="wave" sx={{ height: 40 }} />
      </Box>
    </Container>
  );
}