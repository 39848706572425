import React from 'react'
import { AuthContext } from "../contexts/AuthContext"
import { useNavigate, } from "react-router-dom"
import { Form, Formik, } from 'formik'
import { Box, Button, Card, CardContent, Container, Snackbar, TextField, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { LoginOutlined } from '@mui/icons-material'
import * as Yup from 'yup'
import axios from 'axios'
import { loginUrl } from '../utils/urls'
import { loginFields } from '../utils/formFields'
import { loginValues } from '../utils/formValues'

const schema = Yup.object().shape(
    loginFields.reduce((obj, field) => {
        obj[field.name] = Yup.string().required(`${field.label} is required`)
        return obj
    }, {})
)

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function Login() {
    const { currentUser, setCurrentUser, } = React.useContext(AuthContext)
    const [openAlert, setOpenAlert] = React.useState(false)
    const [severity, setSeverity] = React.useState("")
    const [severityMessage, setSeverityMessage] = React.useState("")
    const navigate = useNavigate()

    React.useEffect(() => {
        currentUser && navigate("/", { replace: true })
    }, [currentUser, navigate])

    const handleClickAlert = () => {
        setOpenAlert(true)
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenAlert(false)
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={severity}
                    sx={{
                        width: '100%'
                    }}
                >
                    {severityMessage}
                </Alert>
            </Snackbar>
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    minHeight: 'calc(100vh - 60px)',
                    backgroundColor: "neutral.100",
                }}
            >
                <Container
                    maxWidth="xs"
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <Box
                            component='img'
                            sx={{
                                width: "40%",
                                mx: 'auto',
                            }}
                            alt="Logo"
                            src={require("../assets/images/UDOM_LOGO.png")}
                        />
                    </Box>
                    <Box
                        sx={{
                            py: 1,
                        }}
                    >
                        <Typography
                            align="center"
                            color="textSecondary"
                            variant="h6"
                        >
                            Real Time Active Learning Tool
                        </Typography>
                    </Box>
                    <Card
                        elevation={10}
                    >
                        <CardContent>
                            <Formik
                                initialValues={{ ...loginValues[0] }}
                                validationSchema={schema}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {
                                    setSubmitting(true)
                                    try {
                                        const response = await axios.post(
                                            loginUrl,
                                            values,
                                            {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                                                },
                                            }
                                        )
                                        if (response.data.error === false) {
                                            const user = JSON.stringify(response.data.data)
                                            localStorage.setItem('carRentingWebUser', user)
                                            resetForm()
                                            setCurrentUser(response.data.data)
                                            setSubmitting(false)
                                            navigate("/", { replace: true })
                                        }
                                    } catch (error) {
                                        setSeverityMessage(error.response.data.message[0])
                                        setSeverity("error")
                                        handleClickAlert()
                                        setSubmitting(false)
                                    }
                                }}
                            >
                                {({ isSubmitting, values, touched, errors, handleChange, handleBlur, }) => (
                                    <Form
                                        noValidate
                                        autoComplete="off"
                                    >
                                        {loginFields.map((field) => (
                                            <TextField
                                                color='secondary'
                                                name={field.name}
                                                type={field.type}
                                                label={field.label}
                                                margin="dense"
                                                fullWidth
                                                key={field.name}
                                                value={values[field.name]}
                                                error={Boolean(errors[field.name] && touched[field.name])}
                                                helperText={touched[field.name] && errors[field.name]}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        ))
                                        }
                                        <Box
                                            sx={{ pt: 2 }}
                                        >
                                            <Button
                                                startIcon={<LoginOutlined />}
                                                color="primary"
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: "primary.main",
                                                    },
                                                }}
                                            >
                                                Log In
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
            {/* <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    left: 0,
                    height: 60,
                    width: "100%",
                    backgroundColor: "background.paper",
                    display: 'flex',
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "flex-end",
                }}
            >
                <Typography>
                    Copyright © {new Date().getFullYear()} real time active learning tool.
                </Typography>
            </Box> */}
        </>
    )
}