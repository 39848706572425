import { MenuItem, MenuList, Popover } from '@mui/material'
import React from 'react'
import uuid from 'react-uuid'
import { downloadItems } from '../utils/constants'

function DownloadPopOver({
    id,
    open,
    anchorEl,
    handleClose,
    handleExport,
    headCells,
    rows,
    from,
    isLoading,
}) {
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: { width: '150px' }
            }}
        >
            <MenuList
                disablePadding
                sx={{
                    '& > *': {
                        '&:first-of-type': {
                            borderTopColor: 'divider',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px'
                        },
                        padding: '12px 16px'
                    }
                }}
            >
                {downloadItems.map((item, index) => (
                    <MenuItem
                        key={uuid()}
                        onClick={(event) => {
                            handleExport(item.fileType, headCells, rows, from, isLoading)
                            handleClose()
                        }}
                    >
                        {item.downloadName}
                    </MenuItem>
                ))}
            </MenuList>
        </Popover>
    )
}

export default DownloadPopOver