import React from 'react'
import { Box, Container, Grid, Skeleton } from '@mui/material'
import DashBoardCard from '../components/DashBoardCard'
import uuid from 'react-uuid'
import { BookIcon } from '../icons/book'
import { Link } from 'react-router-dom'
import { UserIcon } from '../icons/user'
import { AuthContext } from '../contexts/AuthContext'
import { admin, instructor } from '../utils/constants'
import { getAdminDashboardStatisticsUrl, getCoursesForSpecificInstructorUrl, getCoursesForSpecificStudentUrl } from '../utils/urls'
import { postAPIRequest } from '../services/postAPIRequest'

export default function Home() {
  const { currentUser } = React.useContext(AuthContext)
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  const adminItems = currentUser.role === admin ? [
    {
      title: 'Administrators',
      subtitle: rows[0]?.total_admin,
      body: new Date().getFullYear(),
      icon: (<UserIcon />),
      href: '/adminstrators',
    },
    {
      title: 'Instructors',
      subtitle: rows[0]?.total_instructors,
      body: new Date().getFullYear(),
      icon: (<UserIcon />),
      href: '/instructors',
    },
    {
      title: 'Students',
      subtitle: rows[0]?.total_students,
      body: new Date().getFullYear(),
      icon: (<UserIcon />),
      href: '/students',
    },
    {
      title: 'DEPARTMENTS',
      subtitle: rows[0]?.total_departments,
      body: new Date().getFullYear(),
      icon: (<BookIcon />),
      href: '/departments',
    },
    {
      title: 'PROGRAMMES',
      subtitle: rows[0]?.total_programmes,
      body: new Date().getFullYear(),
      icon: (<BookIcon />),
      href: '/programmes',
    },
    {
      title: 'COURSES',
      subtitle: rows[0]?.total_courses,
      body: new Date().getFullYear(),
      icon: (<BookIcon />),
      href: '/courses',
    },
  ] : []

  const url = currentUser.role === instructor ?
    getCoursesForSpecificInstructorUrl :
    currentUser.role === admin ?
      getAdminDashboardStatisticsUrl :
      getCoursesForSpecificStudentUrl

  React.useEffect(() => {
    postAPIRequest(
      url,
      { "user_id": currentUser.id },
      setRows,
      setIsLoading
    )
  }, [url, currentUser])

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            {isLoading === true &&
              <>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                  <Skeleton variant='rectangular' height="17vh" />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                  <Skeleton variant='rectangular' height="17vh" />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                  <Skeleton variant='rectangular' height="17vh" />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                  <Skeleton variant='rectangular' height="17vh" />
                </Grid>
              </>
            }
            {currentUser.role !== admin && rows.map((item) => (
              <Grid
                key={uuid()}
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                sx={{
                  '&:hover': {
                    color: "red",
                  },
                }}
              >
                {isLoading === false &&
                  <Link
                    to={{
                      pathname: currentUser.role === instructor ?
                        `/create-polls/${item.course_code}/default` :
                        `/answer-polls/${item.course_code}`,
                    }}
                    replace={true}
                  >
                    <DashBoardCard
                      title={item.course_code}
                      subtitle={item.subtitle}
                      body={item.course_name}
                      icon={<BookIcon />}
                    />
                  </Link>}
              </Grid>
            ))}
            {currentUser.role === admin && adminItems.map((item) => (
              <Grid
                key={uuid()}
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                sx={{
                  '&:hover': {
                    color: "red",
                  },
                }}
              >
                {isLoading === false &&
                  <Link
                    to={item.href}
                    replace={true}
                  >
                    <DashBoardCard
                      title={item.title}
                      subtitle={item.subtitle}
                      body={item.body}
                      icon={item.icon}
                    />
                  </Link>}
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  )
}