import { Delete, ThumbDown, ThumbUp } from '@mui/icons-material'
import { Box, Button, Container, Divider, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import React from 'react'
import uuid from 'react-uuid'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { addPollResponseUrl, deletePollResponseUrl, likeDislikePollResponseUrl, publicBaseUrl } from '../../utils/urls'
import { AuthContext } from '../../contexts/AuthContext'
import axios from 'axios'
import { collection, doc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore'
import { db } from '../../firebase'
import CustomSkeleton from '../../components/CustomSkeleton'
// import Image from '../../assets/UDOM_LOGO.png'

export default function AnswerPolls() {
  const { currentUser } = React.useContext(AuthContext)
  const { courseId, } = useParams()
  const [poll, setPoll] = React.useState({})
  const [livePollResponse, setLivePollResponse] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  React.useEffect(() => {
    const fetchPoll = async () => {
      const responsesRef = doc(db, courseId, "question")
      const unsubscribePoll = onSnapshot(responsesRef, async (document) => {
        if (document.exists() && document.data().active) {
          const ref = query(
            collection(db, courseId, "question", "responses"),
            where('poll_id', '==', document.data().id),
            where('cleared', '==', false),
            where('status', '==', false),
          )
          const querySnapshot = await getDocs(ref)
          const responsesData = []
          for (const document of querySnapshot.docs) {
            responsesData.push({ ...document.data() })
          }
          setLivePollResponse(responsesData)
          setPoll(document.data())
        } else {
          setPoll([])
          setLivePollResponse([])
        }
        setIsLoading(false)
      })

      return () => {
        unsubscribePoll()
      }
    }

    fetchPoll()
  }, [courseId, setIsLoading, poll])

  const formik = useFormik({
    initialValues: {
      response: '',
    },
    validationSchema: Yup.object({
      response: Yup
        .string()
        .max(255)
        .required('Response cannot be empty.'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      try {
        const response = await axios.post(
          addPollResponseUrl,
          {
            "poll_id": poll?.id,
            "user_id": currentUser?.id,
            "response": values.response,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
              'real-time-token-auth': currentUser?.token,
            },
          }
        )
        if (response.data.error === false) {
          const specificQuestionsRef = doc(db, courseId, "question", "responses", response.data.data.id)
          await setDoc(
            specificQuestionsRef,
            { ...response.data.data, poll_id: poll?.id }
          )
          resetForm()
          setSubmitting(false)
        }
      } catch (error) {
        setSubmitting(false)
      }
    }
  })

  const addMultipleChoiceResponse = async (answer) => {
    setIsSubmitting(true)
    try {
      const response = await axios.post(
        addPollResponseUrl,
        {
          "poll_id": poll?.id,
          "user_id": currentUser?.id,
          "response": answer,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        const specificQuestionsRef = doc(db, courseId, "question", "responses", response.data.data.id)
        await setDoc(
          specificQuestionsRef,
          { ...response.data.data, poll_id: poll?.id }
        )
        setIsSubmitting(false)
      }
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const likeDislike = async (responseData, action) => {
    setIsSubmitting(true)
    try {
      const response = await axios.post(
        likeDislikePollResponseUrl,
        {
          "poll_id": poll?.id,
          "user_id": currentUser?.id,
          "response_id": responseData.id,
          "action": action,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        localStorage.setItem(`${responseData.id}_${currentUser.id}_liked`, response.data.data.liked)
        localStorage.setItem(`${responseData.id}_${currentUser.id}_disliked`, response.data.data.disliked)
        const specificQuestionsRef = doc(db, courseId, "question", "responses", responseData.id)
        await updateDoc(
          specificQuestionsRef,
          { ...response.data.data, poll_id: poll?.id }
        )
        setIsSubmitting(false)
      }
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const deleteResponse = async (responseId) => {
    setIsSubmitting(true)
    try {
      const response = await axios.post(
        deletePollResponseUrl,
        {
          "user_id": currentUser?.id,
          "response_id": responseId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        const specificQuestionsRef = doc(db, courseId, "question", "responses", responseId)
        await updateDoc(
          specificQuestionsRef,
          { status: true, }
        )
        setIsSubmitting(false)
      }
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {isLoading === true &&
        <CustomSkeleton />
      }
      {!poll.id && isLoading === false &&
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            height: "calc(100vh - 200px)",
            // backgroundImage: `url(${Image})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            // backgroundPosition: "center",
          }}
        >
          <Typography
            variant='caption'
            align='center'
            sx={{ fontSize: "40px" }}
          >
            Waiting for presentation to begin...
          </Typography>
        </Container>
      }
      {poll.id && isLoading === false &&
        <Container maxWidth="lg">
          <List>
            <ListItem>
              {poll?.file_path !== "" &&
                <Box
                  edge="start"
                  component='img'
                  sx={{
                    width: 1 / 4,
                  }}
                  alt="Logo"
                  src={publicBaseUrl + poll?.file_path}
                />
              }
              <Typography
                color='text.secondary'
                variant="h6"
              >
                {poll?.poll}
              </Typography>
            </ListItem>
            {poll?.poll_type !== "multipleChoices" &&
              <form onSubmit={formik.handleSubmit}>
                <ListItem
                  disablePadding
                >
                  <TextField
                    error={Boolean(formik.touched.response && formik.errors.response)}
                    fullWidth
                    helperText={formik.touched.response && formik.errors.response}
                    label="Enter response"
                    margin="dense"
                    name="response"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.response}
                    variant="outlined"
                    multiline
                  />
                </ListItem>
                <ListItem
                  disablePadding
                >
                  {livePollResponse
                    .filter(item => item.user_id === currentUser.id)
                    .length < poll?.times_to_respond &&
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth={true}
                      sx={{ my: 3, }}
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      Submit
                    </Button>}
                  {livePollResponse
                    .filter(item => item.user_id === currentUser.id)
                    .length >= poll?.times_to_respond &&
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth={true}
                      sx={{ my: 3, }}
                      disabled={true}
                    >
                      Submit
                    </Button>}
                </ListItem>
              </form>
            }
            {poll?.poll_type === "multipleChoices" && poll?.poll_options?.map((option, index) => {
              return (
                <ListItem
                  disablePadding
                  key={uuid()}
                >
                  {livePollResponse
                    .filter(item => item.user_id === currentUser.id)
                    .length < poll?.times_to_respond &&
                    <Button
                      color="primary"
                      endIcon={
                        livePollResponse
                          .filter(item => item.user_id === currentUser.id)
                          .some(item => item.response === option.option) &&
                        <Delete />
                      }
                      variant="contained"
                      fullWidth={true}
                      sx={{ mb: 3, }}
                      disabled={isSubmitting}
                      onClick={() => addMultipleChoiceResponse(option.option)}
                    >
                      {option.option}
                    </Button>}
                  {livePollResponse
                    .filter(item => item.user_id === currentUser.id)
                    .length >= poll?.times_to_respond &&
                    <Button
                      color={"primary"}
                      endIcon={
                        livePollResponse
                          .filter(item => item.user_id === currentUser.id)
                          .some(item => item.response === option.option) &&
                        <Delete />
                      }
                      variant="contained"
                      fullWidth={true}
                      sx={{ mb: 3, }}
                      disabled={
                        livePollResponse
                          .filter(item => item.user_id === currentUser.id)
                          .some(item => item.response === option.option) ?
                          isSubmitting :
                          true
                      }
                      onClick={() => {
                        const response = livePollResponse
                          .filter(item => item.user_id === currentUser.id)
                        deleteResponse(response[0].id)
                      }}
                    >
                      {option.option}
                    </Button>}
                </ListItem>
              )
            })}
            {poll?.poll_type === "wordCloud" &&
              livePollResponse
                .filter(item => item.user_id === currentUser.id)
                .map((response, index) => {
                  return (
                    <div
                      key={uuid()}
                    >
                      <ListItem
                        disablePadding
                      >
                        <ListItemText primary={response.response} />
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="close"
                          disabled={isSubmitting}
                          onClick={() => deleteResponse(response.id)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItem>
                      <Divider color='primary' />
                    </div>
                  )
                })}
            {poll?.poll_type === "questionAndAnswer" &&
              livePollResponse
                // .filter(item => item.user_id !== currentUser.id)
                .map((response, index) => {
                  return (
                    <div
                      key={uuid()}
                    >
                      <ListItem
                        disablePadding
                      >
                        <ListItemText primary={response.response} />
                        <Box
                          edge="end"
                        >
                          <Button
                            color={
                              localStorage.getItem(`${response.id}_${currentUser.id}_liked`) === "true" ?
                                "primary" : "inherit"
                            }
                            startIcon={<ThumbUp />}
                            disabled={isSubmitting}
                            onClick={() => likeDislike(response, "like")}
                          >
                            {response.total_likes}
                          </Button>
                          <Button
                            color={
                              localStorage.getItem(`${response.id}_${currentUser.id}_disliked`) === "true" ?
                                "primary" : "inherit"
                            }
                            startIcon={<ThumbDown />}
                            disabled={isSubmitting}
                            onClick={() => likeDislike(response, "dislike")}
                          >
                            {response.total_dislikes}
                          </Button>
                        </Box>
                      </ListItem>
                      <Divider color='primary' />
                    </div>
                  )
                })}
          </List>
        </Container>
      }
    </>
  )
}