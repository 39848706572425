import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Form, Formik, } from "formik"
import * as Yup from "yup"
import { Autocomplete, Avatar, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, TextField, } from '@mui/material'
import uuid from 'react-uuid'
import { genders } from '../utils/constants'
import { BookOutlined } from '@mui/icons-material'
import axios from 'axios'
import { AuthContext } from '../contexts/AuthContext'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function FormDialog({
    open,
    handleClose,
    title,
    label,
    fields,
    values,
    action,
    handlesubmitForm,
}) {
    const { currentUser, } = React.useContext(AuthContext)
    const [options, setOptions] = React.useState([])
    const [value, setValue] = React.useState("")

    const schema = Yup.object().shape(
        fields.reduce((obj, field) => {
            obj[field.name] = Yup.string().min(3, `${field.label} must be at least 3 characters`)
                .required(`${field.label} is required`)
            return obj
        }, {})
    )

    const fetchOptions = async (searchValue, url) => {
        try {
            const response = await axios.post(
                url,
                { "query": searchValue },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'real-time-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                setOptions(response.data.data)
            }
        } catch (error) {
            setOptions([])
        }
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            fullWidth={label === "folder" ? false : true}
            maxWidth={label === "folder" ? "xs" : "sm"}
        >
            <Formik
                initialValues={{ ...values[0] }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
                    // console.log("onSubmit", JSON.stringify(values, null, 2))
                    handlesubmitForm(values, setSubmitting, resetForm, setErrors)
                }}
            >
                {({ isSubmitting, values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
                    <Form
                        noValidate
                        autoComplete="off"
                    >
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            {fields.map((field, index) => {

                                return (
                                    <React.Fragment key={field.name}>
                                        {field.name === "gender" ?
                                            <TextField
                                                id={field.name}
                                                select
                                                margin='normal'
                                                label={field.label}
                                                value={values[field.name]}
                                                onChange={(event) => {
                                                    field.name === "gender" && setFieldValue('gender', event.target.value)
                                                }}
                                                fullWidth
                                            >
                                                {genders.map((department) => (
                                                    <MenuItem
                                                        key={uuid()}
                                                        value={department.value}
                                                    >
                                                        {department.value}
                                                    </MenuItem>
                                                ))}
                                            </TextField> :
                                            field.searchUrl ?
                                                <Autocomplete
                                                    options={options}
                                                    getOptionLabel={(option) =>
                                                        field.name === "programme_name" ?
                                                            option.programme_name.toString() :
                                                            field.name === "department_name" ?
                                                                option.department_name.toString() :
                                                                field.name === "instructor_name" ?
                                                                    option.full_name.toString() :
                                                                    option.course_name.toString()
                                                    }
                                                    filterOptions={(x) => x}
                                                    noOptionsText="No items"
                                                    includeInputInList
                                                    filterSelectedOptions
                                                    onChange={(event, value) => {
                                                        setFieldValue(field.name, value.id)
                                                    }}
                                                    renderOption={(props, option) => {

                                                        return (
                                                            <li {...props}>
                                                                <List sx={{ width: "100%" }}>
                                                                    <ListItem>
                                                                        <ListItemAvatar>
                                                                            <Avatar>
                                                                                <BookOutlined />
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                field.name === "programme_name" ?
                                                                                    option.programme_name :
                                                                                    field.name === "department_name" ?
                                                                                        option.department_name :
                                                                                        field.name === "instructor_name" ?
                                                                                            option.full_name :
                                                                                            option.course_name
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </li>
                                                        )
                                                    }}
                                                    onInputChange={() => setOptions([])}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={field.label}
                                                            color='secondary'
                                                            fullWidth
                                                            margin='normal'
                                                            variant="outlined"
                                                            value={value}
                                                            error={Boolean(errors[field.name] && touched[field.name])}
                                                            helperText={touched[field.name] && errors[field.name]}
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                setValue(event.target.value)
                                                                fetchOptions(event.target.value, field.searchUrl)
                                                            }}
                                                        />
                                                    )}
                                                /> :
                                                <TextField
                                                    required
                                                    name={field.name}
                                                    type={field.type}
                                                    label={field.label}
                                                    margin="normal"
                                                    fullWidth
                                                    value={values[field.name]}
                                                    error={Boolean(errors[field.name] && touched[field.name])}
                                                    helperText={touched[field.name] && errors[field.name]}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />}
                                    </React.Fragment>
                                )
                            })}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {action === "create" ? `Add ${label}` : `Update ${label}`}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}