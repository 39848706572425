import axios from "axios"

export const postAPIRequest = async (url, body, setData, setIsLoading, setFolders, from, setItem) => {
    setData([])
    try {
        const storedUserMap = localStorage.getItem('carRentingWebUser')
        const mapStoredUser = JSON.parse(storedUserMap)
        setIsLoading(true)
        const response = await axios.post(
            url,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                    'real-time-token-auth': mapStoredUser.token,
                },
            }
        )
        if (response.data.error === false) {
            if (from === "polls") {
                setFolders(response.data.data.sub_folders)
                setData(response.data.data.polls)
                setItem(response.data.data)
            } else {
                setData(response.data.data)
            }
        }
        setIsLoading(false)
    } catch (error) {
        setIsLoading(false)
    }
}