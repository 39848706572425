import * as React from 'react'
import { AppBar, Box, IconButton, MenuItem, MenuList, Popover, Snackbar, styled, Toolbar, } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { ArrowDropDown, Menu, } from '@mui/icons-material'
import { AuthContext } from "../../contexts/AuthContext"
import { changePasswordFields } from '../../utils/formFields'
import { changePasswordValues } from '../../utils/formValues'
import FormDialog from '../FormDialog'
import axios from 'axios'
import { changePasswordUrl } from '../../utils/urls'

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function NavBar({ onSidebarOpen, }) {
    const { currentUser, setCurrentUser } = React.useContext(AuthContext)
    const [openAlert, setOpenAlert] = React.useState(false)
    const [severity, setSeverity] = React.useState("")
    const [severityMessage, setSeverityMessage] = React.useState("")

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleSignOut = async () => {
        localStorage.clear()
        setCurrentUser(false)
    }

    const [openFormFialog, setOpenFormFialog] = React.useState(false)

    const handleClickOpenFormFialog = () => {
        handleClose()
        setOpenFormFialog(true)
    }

    const handleCloseFormFialog = () => {
        setOpenFormFialog(false)
    }

    const handleClickAlert = () => {
        setOpenAlert(true)
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenAlert(false)
    }

    const handlesubmitForm = async (password, setSubmitting, resetForm, setErrors) => {
        setSubmitting(true)
        try {
            const response = await axios.post(
                changePasswordUrl,
                {
                    ...password, "user_id": currentUser?.id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'real-time-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                resetForm()
                handleClose()
                setSeverityMessage(response.data.message)
                setSeverity("success")
                handleClickAlert()
                setSubmitting(false)
            }
        } catch (error) {
            setErrors(error.response.data)
            error.response.data.message[0] && setSeverityMessage(error.response.data.message[0])
            setSeverity("error")
            handleClickAlert()
            setSubmitting(false)
        }
    }

    return (
        <DashboardNavbarRoot
            sx={{
                left: {
                    lg: 280
                },
                width: {
                    lg: 'calc(100% - 280px)'
                }
            }}
        >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={severity}
                    sx={{
                        width: '100%'
                    }}
                >
                    {severityMessage}
                </Alert>
            </Snackbar>
            <FormDialog
                open={openFormFialog}
                handleClose={handleCloseFormFialog}
                title="Change Password"
                label="password"
                fields={changePasswordFields}
                values={changePasswordValues}
                action="edit"
                handlesubmitForm={handlesubmitForm}
            />
            <Toolbar
                disableGutters
                sx={{
                    minHeight: 64,
                    left: 0,
                    px: 2
                }}
            >
                <IconButton
                    onClick={onSidebarOpen}
                    sx={{
                        display: {
                            xs: 'inline-flex',
                            lg: 'none'
                        }
                    }}
                >
                    <Menu fontSize="small" />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                    onClick={handleClick}
                    aria-describedby={id}
                    sx={{
                        cursor: 'pointer',
                        ml: 1,
                        '&:hover': {
                            backgroundColor: "background.paper",
                        },
                    }}
                >
                    {currentUser?.full_name}
                    <ArrowDropDown />
                </IconButton>
            </Toolbar>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: { width: '300px' }
                }}
            >
                <MenuList
                    disablePadding
                    sx={{
                        '& > *': {
                            '&:first-of-type': {
                                borderTopColor: 'divider',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px'
                            },
                            padding: '12px 16px'
                        }
                    }}
                >
                    <MenuItem onClick={handleClickOpenFormFialog}>
                        Change Password
                    </MenuItem>
                    <MenuItem onClick={handleSignOut}>
                        logout
                    </MenuItem>
                </MenuList>
            </Popover>
        </DashboardNavbarRoot>
    )
}