import { ArrowBack, ArrowForward, ChevronLeft, Clear, PlayCircleOutlineRounded } from '@mui/icons-material'
import { AppBar, Button, Container, Grid, IconButton, Skeleton, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link, useParams, } from 'react-router-dom'
import DisplayQA from '../../components/DisplayQA'
import DisplayWordCloud from '../../components/DisplayWordCloud'
import MultipleChoiceChart from '../../components/MultipleChoiceChart'
import { AuthContext } from '../../contexts/AuthContext'
import { activatePollUrl, publicBaseUrl, clearPollResponseUrl, deactivatePollUrl, getFolderAndPollsUrl, getSpecificPollResponseHistoryUrl } from '../../utils/urls'
import axios from 'axios'
import { doc, setDoc, updateDoc, writeBatch, } from 'firebase/firestore'
import { db } from '../../firebase'

export default function ActivatePolls() {
  const { currentUser, currentIndex } = React.useContext(AuthContext)
  const { courseId, folderId, } = useParams()
  const [polls, setPolls] = React.useState([])
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [current, setCurrent] = React.useState(currentIndex)

  React.useEffect(() => {
    document.body.addEventListener('keydown', onkeydown)
    document.body.removeEventListener('keydown', onkeydown)
  }, [])

  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null)
  }

  const nextSlide = () => {
    (polls?.polls?.length - 1) > current && setCurrent(current + 1)
  }

  const prevSlide = () => {
    current > 0 && setCurrent(current - 1)
  }

  const handleKeyDown = React.useCallback(async (event) => {
    if (event.key === 'Escape') {
      setIsFullScreen(false)
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange)
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const response = await axios.post(
          getFolderAndPollsUrl,
          {
            "course_code": courseId,
            "folder_id": folderId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
              'real-time-token-auth': currentUser?.token,
            },
          }
        )
        if (response.data.error === false) {
          setPolls(response.data.data)
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
      }
    }

    isLoading && fetch()
  }, [courseId, folderId, currentUser, isLoading])

  React.useEffect(() => {
    const deactivate = async () => {
      try {
        const response = await axios.post(
          deactivatePollUrl,
          {
            "user_id": currentUser?.id,
            "course_code": courseId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
              'real-time-token-auth': currentUser?.token,
            },
          }
        )
        if (response.data.error === false) {
          setIsFullScreen(false)
          const specificQuestionsRef = doc(db, courseId, "question")
          await updateDoc(
            specificQuestionsRef,
            { active: false, }
          )
        }
      } catch (error) {
        setIsFullScreen(false)
      }
    }

    isFullScreen === false && deactivate()
  }, [courseId, currentUser, isFullScreen, current, polls])

  const updatePoll = async () => {
    try {
      const response = await axios.post(
        activatePollUrl,
        {
          "poll_id": polls?.polls && polls?.polls[current]?.id,
          "user_id": currentUser?.id,
          "course_code": courseId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        document.documentElement.requestFullscreen()
        sendDataToFirebase(polls?.polls && polls?.polls[current].id, false)
      }
    } catch (error) {
      setIsFullScreen(false)
    }
  }

  const clearResponses = async () => {
    try {
      const response = await axios.post(
        clearPollResponseUrl,
        {
          "poll_id": polls?.polls && polls?.polls[current]?.id,
          "user_id": currentUser?.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        setIsLoading(true)
        sendDataToFirebase(polls?.polls && polls?.polls[current].id, true)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  onkeydown = async (event) => {
    const isLeft = event.key === 'ArrowLeft'
    const isRight = event.key === 'ArrowRight'
    if (isLeft && isFullScreen === true) {
      if (current > 0) {
        try {
          const response = await axios.post(
            activatePollUrl,
            {
              "poll_id": polls?.polls[current - 1]?.id,
              "user_id": currentUser?.id,
              "course_code": courseId,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                'real-time-token-auth': currentUser?.token,
              },
            }
          )
          if (response.data.error === false) {
            setCurrent(current - 1)
            sendDataToFirebase(polls?.polls && polls?.polls[current - 1].id, false)
          }
        } catch (error) {
          setIsLoading(false)
        }
      }
    }
    if (isRight && isFullScreen === true) {
      if ((polls?.polls?.length - 1) > current) {
        try {
          const response = await axios.post(
            activatePollUrl,
            {
              "poll_id": polls?.polls[current + 1]?.id,
              "user_id": currentUser?.id,
              "course_code": courseId,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                'real-time-token-auth': currentUser?.token,
              },
            }
          )
          if (response.data.error === false) {
            setCurrent(current + 1)
            sendDataToFirebase(polls?.polls && polls?.polls[current + 1].id, false)
          }
        } catch (error) {
          setIsLoading(false)
        }
      }
    }
  }

  const sendDataToFirebase = async (pollId, sendResponses) => {
    try {
      const response = await axios.post(
        getSpecificPollResponseHistoryUrl,
        {
          "poll_id": pollId,
          "user_id": currentUser?.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        try {
          const questionsRef = doc(db, courseId, "question")
          const pollData = response.data.data
          const { poll_responses, ...pollDataWithoutResponses } = pollData
          await setDoc(
            questionsRef,
            pollDataWithoutResponses
          )

          if (sendResponses) {
            const batch = writeBatch(db)
            const pollId = pollData.id
            const responsesUpdates = pollData.poll_responses.map((response) => {
              const responsesRef = doc(db, courseId, "question", "responses", response.id)
              return batch.set(
                responsesRef,
                { ...response, poll_id: pollId },
              )
            })
            await Promise.all(responsesUpdates)
            await batch.commit()
          }
        } catch (error) {
          setIsLoading(false)
        }
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isFullScreen === false &&
        <AppBar sx={{ position: 'relative' }} color='background'>
          <Toolbar>
            <ChevronLeft />
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              <Link
                to={`/create-polls/${courseId}/${folderId}`}
                replace={true}
              >
                Activities
              </Link>
            </Typography>
            <Button
              autoFocus
              color="primary"
              startIcon={<PlayCircleOutlineRounded />}
              variant="contained"
              size="small"
              onClick={() => updatePoll()}
            >
              Present
            </Button>
          </Toolbar>
        </AppBar>
      }
      {isLoading === true &&
        <Skeleton variant='rectangular' width={"100vw"} height={"100vh"} />
      }
      {isLoading === false &&
        <Container
          maxWidth="xl"
          sx={{
            mx: "auto",
            my: 3,
          }}
        >
          <Typography color='text.secondary' variant="h5" sx={{ textAlign: "center", }}>
            {polls?.polls && polls?.polls[current]?.poll}
          </Typography>
          <Grid container sx={{ my: "auto", mx: "auto", }}>
            {polls?.polls && polls?.polls[current]?.file_path !== "" &&
              polls?.polls && polls?.polls[current]?.poll_type !== "questionAndAnswer" ? (
              <Grid item xs={11} lg={3} sm={11} xl={3}>
                <Box
                  component='img'
                  sx={{
                    width: 1,
                    mx: 'auto',
                    my: 5,
                  }}
                  alt="Poll image"
                  src={polls?.polls && publicBaseUrl + polls?.polls[current]?.file_path}
                />
              </Grid>
            ) : null
            }
            {polls?.polls && polls?.polls[current]?.poll_type === "multipleChoices" ? (
              <Grid
                item
                xs={11}
                lg={isFullScreen === true && polls?.polls && polls?.polls[current]?.file_path !== "" ?
                  9 : isFullScreen === true && polls?.polls && polls?.polls[current]?.file_path === "" ?
                    12 : polls?.polls && polls?.polls[current]?.file_path !== "" ? 8 : 9
                }
                sm={11}
                xl={isFullScreen === true && polls?.polls && polls?.polls[current]?.file_path !== "" ?
                  9 : isFullScreen === true && polls?.polls && polls?.polls[current]?.file_path === "" ?
                    12 : polls?.polls && polls?.polls[current]?.file_path !== "" ? 8 : 9
                }
                sx={{ mx: 'auto' }}
              >
                <MultipleChoiceChart
                  pollId={polls?.polls && polls?.polls[current].id}
                  setIsLoading={setIsLoading}
                  isFullScreen={isFullScreen}
                />
              </Grid>
            ) : polls?.polls && polls?.polls[current]?.poll_type === "wordCloud" ? (
              <Grid
                item
                xs={11}
                lg={5}
                sm={11}
                xl={5}
                sx={{ mx: 'auto' }}
              >
                <DisplayWordCloud
                  pollId={polls?.polls && polls?.polls[current].id}
                  setIsLoading={setIsLoading}
                  isFullScreen={isFullScreen}
                />
              </Grid>
            ) : (
              <DisplayQA
                pollId={polls?.polls && polls?.polls[current].id}
                setIsLoading={setIsLoading}
                isFullScreen={isFullScreen}
              />
            )
            }
          </Grid>
        </Container>
      }
      {isFullScreen === false &&
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            height: 60,
            width: "100%",
            backgroundColor: "text.primary",
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-around"
          }}
        >
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              height: 60,
              width: "50%",
              backgroundColor: "text.primary",
              display: 'flex',
              alignItems: "center",
              justifyContent: "space-around"
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={prevSlide}
              aria-label="close"
            >
              <ArrowBack color='default' />
            </IconButton>
            <Typography
              color='text.secondary'
            >
              {current + 1} / {polls?.polls?.length}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={nextSlide}
              aria-label="close"
            >
              <ArrowForward color='default' />
            </IconButton>
            <Button
              startIcon={<Clear />}
              variant="outlined"
              size="small"
              color="default"
              onClick={clearResponses}
            >
              Clear
            </Button>
          </Box>
        </Box>
      }
    </>
  )
}