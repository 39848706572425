import { AddCircleOutlineRounded, CreateNewFolder, PhotoOutlined } from "@mui/icons-material"
import { styled, TableCell, tableCellClasses, TableRow } from "@mui/material"
import dayjs from "dayjs"
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export const admin = "ADIMINISTRATOR"
export const instructor = "INSTRUCTOR"
export const student = "STUDENT"

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    })
    return stabilizedThis.map((el) => el[0]);
}

export async function handleFolderNavigation(folderId, folderName, currentFolder, setCurrentFolder) {
    setCurrentFolder({
        name: [...currentFolder.name, folderName],
        path: [...currentFolder.path, folderId],
    })
}

export async function handleBreadcrumbNavigation(index, courseId, currentFolder, setCurrentFolder) {
    setCurrentFolder({
        name: currentFolder.name.slice(0, index + 1),
        path: currentFolder.path.slice(0, index + 1),
    })
}

export const handleCorrectAnswersChange = (index, checked, correctAnswers, setCorrectAnswers) => {
    const newCorrectAnswers = [...correctAnswers]
    if (checked) {
        newCorrectAnswers.push(index)
    } else {
        const indexToRemove = newCorrectAnswers.indexOf(index)
        newCorrectAnswers.splice(indexToRemove, 1)
    }
    setCorrectAnswers(newCorrectAnswers)
}

export const pollsButtonItems = [
    {
        title: 'Activity',
        margin: 2,
        variant: 'contained',
        icon: <AddCircleOutlineRounded />
    },
    {
        title: 'Folder',
        margin: 2,
        variant: 'text',
        icon: <CreateNewFolder />,
    },
]

export const times = [
    { value: 1, },
    { value: 2, },
    { value: 3, },
    { value: 4, },
    { value: 5, },
    { value: 6, },
    { value: 7, },
    { value: 8, },
    { value: 9, },
    { value: 10, },
]

export const actionButtons = [
    {
        title: 'Import Excel',
        margin: 2,
        icon: <PhotoOutlined />,
    },
    {
        title: 'Add',
        margin: 2,
        icon: <AddCircleOutlineRounded />,
    },
]

export const assignCourseInstructor = [
    { title: 'Assign Course To Instructor', margin: 2, },
]

export const assignCourseProgramme = [
    { title: 'Assign Course To Programme', margin: 2, },
]

export const body = {
    "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().add(5, "minute").format('YYYY-MM-DD HH:mm:ss.SSS'),
}

export const genders = [
    { value: 'MALE', },
    { value: 'FEMALE', },
]

export const handleExport = (file, headCells, rows, from, isLoading) => {
    if (!isLoading) {
        if (rows.length >= 1) {
            const newRows = rows.map(row => {
                const { id, file_path, status, active, disliked, liked, cleared, ...newRow } = row
                return newRow
            })

            const newHeadCells = headCells.map(row => {
                const { count, file_path, status, active, action, icon, ...newHeadCell } = row
                return newHeadCell
            })

            if (file === "excel") {
                const worksheet = XLSX.utils.json_to_sheet(newRows)

                const workbook = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(workbook, worksheet, from)

                const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })

                const blob = new Blob([excelBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })

                saveAs(blob, `${from}.xlsx`)
            }
            if (file === "pdf") {
                const header = newHeadCells.map((header) => header.label)

                const docDefinition = {
                    content: [
                        { text: 'Data', style: 'header' },
                        {
                            table: {
                                headerRows: 1,
                                widths: new Array(header.length).fill('auto'),
                                body: [
                                    header,
                                    ...rows.map(row => newHeadCells.map(key => row[key.id]))
                                ],
                                layout: {
                                    defaultBorder: false,
                                    fillColor: function (i, node) { return (i % 2 === 0) ? '#f0f0f0' : null; }
                                }
                            }
                        }
                    ],
                    styles: {
                        header: {
                            fontSize: 18,
                            bold: true,
                            margin: [0, 0, 0, 10]
                        }
                    }
                }

                pdfMake.createPdf(docDefinition).download('cars.pdf')
            }
        }
    }
}

export const downloadItems = [
    {
        downloadName: "Excel",
        fileType: "excel",
    },
    {
        downloadName: "PDF",
        fileType: "pdf",
    },
]