import { searchCourseUrl, searchDepartmentUrl, searchProgrammeUrl, searchUserUrl } from "./urls"

export const loginFields = [
    { name: "identity_number", type: "email", label: "Identity number" },
    { name: "password", type: "password", label: "Password" },
]

export const changePasswordFields = [
    { name: "old_password", type: "password", label: 'Current password' },
    { name: "new_password", type: "password", label: 'New password' },
    { name: "confirm_new_password", type: "password", label: 'Confirm password' },
]

export const folderFields = [
    { name: "folder_name", type: "text", label: "Folder Name" },
]

export const departmentFields = [
    { name: "department_name", type: "text", label: "Department Name" },
    { name: "head_of_department", type: "text", label: "Head Of Department" },
]

export const programmeFields = [
    { name: "programme_name", type: "text", label: "Programme Name" },
    { name: "department_name", type: "text", label: "Department Name", searchUrl: searchDepartmentUrl },
]

export const courseFields = [
    { name: "course_name", type: "text", label: "Course Name" },
    { name: "course_code", type: "text", label: "Course Code" },
]

export const assignCourseInstructorFields = [
    { name: "course_name", type: "text", label: "Course Name", searchUrl: searchCourseUrl },
    { name: "instructor_name", type: "text", label: "Instructor Name", searchUrl: searchUserUrl },
]

export const assignCourseProgrammeFields = [
    { name: "course_name", type: "text", label: "Course Name", searchUrl: searchCourseUrl },
    { name: "programme_name", type: "text", label: "Programme Name", searchUrl: searchProgrammeUrl },
]

export const studentFields = [
    { name: "full_name", type: "text", label: "Student Name" },
    { name: "identity_number", type: "text", label: "Registration Number" },
    { name: "gender", type: "text", label: "Gender" },
    { name: "password", type: "text", label: "Password" },
    { name: "programme_name", type: "text", label: "Programme Name", searchUrl: searchProgrammeUrl },
]

export const staffFields = [
    { name: "full_name", type: "text", label: "Staff Name" },
    { name: "identity_number", type: "text", label: "Staff Number" },
    { name: "gender", type: "text", label: "Gender" },
    { name: "password", type: "text", label: "Password" },
    { name: "department_name", type: "text", label: "Department Name", searchUrl: searchDepartmentUrl },
]