import React from 'react'
import WordCloud from 'react-d3-cloud'
import { scaleOrdinal } from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'
import { getSpecificPollResponseUrl } from '../utils/urls'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { useParams } from 'react-router-dom'

export default function DisplayWordCloud({
    pollId,
    setIsLoading,
    isFullScreen,
}) {
    const { currentUser } = React.useContext(AuthContext)
    const [livePollResponse, setLivePollresponse] = React.useState([])
    const { courseId, } = useParams()

    React.useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await axios.post(
                    getSpecificPollResponseUrl,
                    {
                        "poll_id": pollId,
                        "user_id": currentUser?.id,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                            'real-time-token-auth': currentUser?.token,
                        },
                    }
                )
                if (response.data.error === false) {
                    setLivePollresponse(response.data.data.poll_responses)
                    setIsLoading(false)
                }
            } catch (error) {
                setIsLoading(false)
            }
        }

        !isFullScreen && fetchResponses()
    }, [currentUser, pollId, setIsLoading, isFullScreen])

    React.useEffect(() => {
        const fetchResponses = async () => {
            const responsesRef = query(
                collection(db, courseId, "question", "responses"),
                where('poll_id', '==', pollId),
                where('cleared', '==', false),
                where('status', '==', false),
            )
            const unsubscribeResponses = onSnapshot(responsesRef, async (querySnapshot) => {
                const responsesData = []
                for (const document of querySnapshot.docs) {
                    responsesData.push({ ...document.data() })
                }
                setLivePollresponse(responsesData)
                setIsLoading(false)
            })


            return () => {
                unsubscribeResponses()
            }
        }

        isFullScreen && fetchResponses()
    }, [courseId, setIsLoading, pollId, isFullScreen])

    const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10)

    const wordCloudData = React.useMemo(() => {
        return livePollResponse.map((response) => ({
            text: response.response,
            value: 15,
        }))
    }, [livePollResponse])

    // const handleWordClick = React.useCallback((event, d) => {
    //     console.log(`onWordClick: ${d.text}`)
    // }, [])

    // const handleWordMouseOver = React.useCallback((event, d) => {
    //     console.log(`onWordMouseOver: ${d.text}`)
    // }, [])

    // const handleWordMouseOut = React.useCallback((event, d) => {
    //     console.log(`onWordMouseOut: ${d.text}`)
    // }, [])


    return (
        <WordCloud
            data={wordCloudData}
            width={800}
            height={800}
            font="Times"
            fontStyle="italic"
            fontWeight="bold"
            fontSize={(word) => Math.log2(word.value) * 5}
            spiral="rectangular"
            rotate={(word) => word.value % 360}
            padding={5}
            random={Math.random}
            fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
            // onWordClick={handleWordClick}
            // onWordMouseOver={handleWordMouseOver}
            // onWordMouseOut={handleWordMouseOut}
        />
    )
}