import { NavLink, useLocation } from 'react-router-dom'
import { Box, Button, ListItem } from '@mui/material'

export default function NavItem({ href, icon, title, ...others }) {
    const router = useLocation()
    const { pathname } = router
    const active = href ? (pathname === href) : false

    return (
        <NavLink
            end to={href}
        >
            <ListItem
                disableGutters
                sx={{
                    display: 'flex',
                    mb: 0.5,
                    py: 0,
                    px: 2,
                }}
                {...others}
            >
                <Button
                    startIcon={icon}
                    disableRipple
                    sx={{
                        backgroundColor: active && '#5048E5',
                        borderRadius: 1,
                        color: active ? 'white' : 'neutral.900',
                        fontWeight: active && 'fontWeightBold',
                        justifyContent: 'flex-start',
                        px: 3,
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        '& .MuiButton-startIcon': {
                            color: active ? 'white' : 'neutral.900'
                        },
                        '&:hover .MuiButton-startIcon': {
                            color: 'white',
                          },
                        '&:hover': {
                            backgroundColor: '#5048E5',
                            color: 'white',
                        }
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        {title}
                    </Box>
                </Button>
            </ListItem>
        </NavLink>
    )
}