export const loginValues = [
    {
        identity_number: "",
        password: "",
    },
]

export const changePasswordValues = [
    {
        old_password: "",
        new_password: "",
        confirm_new_password: "",
    },
]