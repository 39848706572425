import React, { useRef } from 'react'
import {
    Chart as ChartJS,
    BarController,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js'
import {
    Chart,
    getDatasetAtEvent,
    getElementAtEvent,
    getElementsAtEvent,
} from 'react-chartjs-2'
import { getSpecificPollResponseUrl } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'
import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { useParams } from 'react-router-dom'

ChartJS.register(
    BarController,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
)

export default function MultipleChoiceChart({
    pollId,
    setIsLoading,
    isFullScreen,
}) {
    const { currentUser } = React.useContext(AuthContext)
    const [pollOptions, setPollOptions] = React.useState([])
    const [responses, setResponses] = React.useState([])
    const { courseId, } = useParams()

    React.useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await axios.post(
                    getSpecificPollResponseUrl,
                    {
                        "poll_id": pollId,
                        "user_id": currentUser?.id,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                            'real-time-token-auth': currentUser?.token,
                        },
                    }
                )
                if (response.data.error === false) {
                    const countedResponses = response.data.data.poll_options.map(option => {
                        const count = response.data.data.poll_responses.reduce((accumulator, response) => {
                            if (response.response === option.option) {
                                return accumulator + 1
                            } else {
                                return accumulator
                            }
                        }, 0)
                        return { optionId: option.id, count: count }
                    })
                    setPollOptions(response.data.data.poll_options)
                    setResponses(countedResponses)
                    setIsLoading(false)
                }
            } catch (error) {
                setIsLoading(false)
            }
        }

        !isFullScreen && fetchResponses()
    }, [currentUser, pollId, setIsLoading, isFullScreen])

    React.useEffect(() => {
        const fetchResponses = async () => {
            const responsesRef = query(
                collection(db, courseId, "question", "responses"),
                where('poll_id', '==', pollId),
                where('cleared', '==', false),
                where('status', '==', false),
            )
            const optionsSnapshot = await getDoc(doc(db, courseId, "question"))
            setPollOptions(optionsSnapshot.data().poll_options)
            const unsubscribeResponses = onSnapshot(responsesRef, async (querySnapshot) => {
                const responsesData = []
                for (const document of querySnapshot.docs) {
                    responsesData.push({ ...document.data() })
                }
                const countedResponses = pollOptions.map(option => {
                    const count = responsesData.reduce((accumulator, response) => {
                        if (response.response === option.option) {
                            return accumulator + 1
                        } else {
                            return accumulator
                        }
                    }, 0)
                    return { optionId: option.id, count: count }
                })
                setResponses(countedResponses)
                setIsLoading(false)
            })

            return () => {
                unsubscribeResponses()
            }
        }

        isFullScreen && fetchResponses()
    }, [courseId, setIsLoading, isFullScreen, pollId, pollOptions])

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    }

    const data = {
        labels: pollOptions?.map(option => option.option),
        datasets: [
            {
                type: 'bar',
                label: 'Responses',
                backgroundColor: 'rgb(6, 4, 64)',
                borderColor: 'rgb(6, 4, 64)',
                data: responses?.map(response => response.count),
            },
        ],
    }

    const printDatasetAtEvent = (dataset) => {
        if (!dataset.length) return

        // const datasetIndex = dataset[0].datasetIndex

        // console.log(data.datasets[datasetIndex].label)
    }

    const printElementAtEvent = (element) => {
        if (!element.length) return

        // const { datasetIndex, index } = element[0]

        // console.log(data.labels[index], data.datasets[datasetIndex].data[index])
    }

    const printElementsAtEvent = (elements) => {
        if (!elements.length) return

        // console.log(elements.length)
    }

    const chartRef = useRef(null)

    const onClick = (event) => {
        const { current: chart } = chartRef

        if (!chart) {
            return
        }

        printDatasetAtEvent(getDatasetAtEvent(chart, event));
        printElementAtEvent(getElementAtEvent(chart, event));
        printElementsAtEvent(getElementsAtEvent(chart, event));
    }

    return (
        <Chart
            ref={chartRef}
            type='bar'
            onClick={onClick}
            options={options}
            data={data}
        />
    )
}