import { PersonOutlined, ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material'
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'
import { getSpecificPollResponseUrl } from '../utils/urls'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { useParams } from 'react-router-dom'

export default function DisplayQA({
    pollId,
    setIsLoading,
    isFullScreen,
}) {
    const { currentUser } = React.useContext(AuthContext)
    const [livePollResponse, setLivePollResponse] = React.useState([])
    const { courseId, } = useParams()

    React.useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await axios.post(
                    getSpecificPollResponseUrl,
                    {
                        "poll_id": pollId,
                        "user_id": currentUser?.id,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                            'real-time-token-auth': currentUser?.token,
                        },
                    }
                )
                if (response.data.error === false) {
                    setLivePollResponse(response.data.data.poll_responses)
                    setIsLoading(false)
                }
            } catch (error) {
                setIsLoading(false)
            }
        }

        !isFullScreen && fetchResponses()
    }, [currentUser, pollId, setIsLoading, isFullScreen])

    React.useEffect(() => {
        const fetchResponses = async () => {
            const responsesRef = query(
                collection(db, courseId, "question", "responses"),
                where('poll_id', '==', pollId),
                where('cleared', '==', false),
                where('status', '==', false),
            )
            const unsubscribeResponses = onSnapshot(responsesRef, async (querySnapshot) => {
                const responsesData = []
                for (const document of querySnapshot.docs) {
                    responsesData.push({ ...document.data() })
                }
                setLivePollResponse(responsesData)
                setIsLoading(false)
            })


            return () => {
                unsubscribeResponses()
            }
        }

        isFullScreen && fetchResponses()
    }, [courseId, setIsLoading, pollId, isFullScreen])

    return (
        <>
            {livePollResponse.map((response, index) => {
                return (
                    <List sx={{ width: "100%" }} key={index}>
                        <ListItem
                            secondaryAction={
                                <Box
                                    sx={{ display: "flex" }}
                                >
                                    <ThumbUpOutlined />
                                    <Typography sx={{ ml: 2, mr: 2 }}>
                                        {response.total_likes}
                                    </Typography>
                                    <ThumbDownOutlined />
                                    <Typography sx={{ ml: 2 }}>
                                        {response.total_dislikes}
                                    </Typography>
                                </Box>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonOutlined />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={response.response}
                                secondary={response.created_at}
                            />
                        </ListItem>
                    </List>
                )
            })}
        </>
    )
}