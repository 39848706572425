import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import SideBar from './SideBar'
import NavBar from './NavBar'

export default function Layout({ children }) {
    const LayoutRoot = styled('div')(({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 280
        }
    }))
    const [isSidebarOpen, setSidebarOpen] = useState(true)

    return (
        <>
            <LayoutRoot>
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    {children}
                    <Box
                        sx={{
                            height: 60,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        height: 60,
                        width: "100%",
                        backgroundColor: "background.paper",
                        display: 'flex',
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography
                    >
                        Copyright © {new Date().getFullYear()} real time active learning tool.
                    </Typography>
                </Box>
            </LayoutRoot>
            <NavBar
                onSidebarOpen={() => setSidebarOpen(true)}
            />
            <SideBar
                onClose={() => setSidebarOpen(false)}
                open={isSidebarOpen}
            />
        </>
    )
}