import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
    question: Yup.string().required("Question cannot be empty."),
})

export const multipleChoiceValidationSchema = Yup.object().shape({
    question: Yup.string().required("Question cannot be empty."),
    options: Yup.array().of(Yup.string().required("Option cannot be empty."))
        .required("Please enter atleast one option"),
})