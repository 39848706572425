import * as React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, } from '@mui/material'
import axios from 'axios'
import { AuthContext } from '../contexts/AuthContext'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function Delete({
    openDeleteDialog,
    handleCloseDeleteDialog,
    url,
    body,
    selected,
    setSeverity,
    setSeverityMessage,
    handleClickAlert,
    setHistory,
    isSubmitting,
    setIsSubmitting,
}) {
    const { currentUser, } = React.useContext(AuthContext)

    const handleDelete = async () => {
        setIsSubmitting(true)
        try {
            const response = await axios.post(
                url,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'real-time-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                setHistory(selected)
                handleCloseDeleteDialog()
                setSeverityMessage(response.data.message)
                setSeverity("success")
                handleClickAlert()
                setIsSubmitting(false)
            }
        } catch (error) {
            handleCloseDeleteDialog()
            setSeverityMessage(error.response.data.message[0])
            setSeverity("error")
            handleClickAlert()
            setIsSubmitting(false)
        }
    }

    return (
        <Dialog
            open={openDeleteDialog}
            TransitionComponent={Transition}
            onClose={handleCloseDeleteDialog}
            aria-describedby="confirmation-alert-dialog"
        >
            <DialogTitle>
                {"Delete item"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="confirmation-alert-dialog"
                >
                    {"The data will be deleted and will not appear on the system."}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCloseDeleteDialog}
                >
                    {"Discard"}
                </Button>
                <Button
                    onClick={handleDelete}
                    disabled={isSubmitting}
                >
                    {"Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}