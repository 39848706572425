import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Autocomplete, Avatar, Box, DialogContentText, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography, } from '@mui/material'
import { BookOutlined, PhotoOutlined } from '@mui/icons-material'
import * as XLSX from 'xlsx'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'

export default function ExcelDialogForm({
    openExcelDialog,
    handleCloseExcelDialog,
    title,
    url,
    handleRefresh,
    searchUrl,
    setSeverityMessage,
    setSeverity,
    handleClickAlert,
    imagePath,
}) {
    const { currentUser, } = React.useContext(AuthContext)
    const [selectedImage, setSelecedimage] = React.useState(null)
    const [imageurl, setImageUrl] = React.useState(null)
    const [body, setBody] = React.useState([])
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [value, setValue] = React.useState("")
    const [searchId, setSearchId] = React.useState(0)

    React.useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage))
        }
        !searchUrl && setSearchId(1)
    }, [selectedImage, searchUrl])

    const readUploadFile = (e) => {
        e.preventDefault()
        if (e.target.files) {
            setSelecedimage(e.target.files[0])
            const reader = new FileReader()
            reader.onload = (e) => {
                const data = e.target.result
                const workbook = XLSX.read(data, { type: "array" })
                const sheetName = workbook.SheetNames[0]
                const worksheet = workbook.Sheets[sheetName]
                const json = XLSX.utils.sheet_to_json(worksheet)
                setBody(json)
            }
            reader.readAsArrayBuffer(e.target.files[0])
        }
    }

    const handlesubmitExcel = async () => {
        setIsSubmitting(true)
        try {
            if (searchId >= 1 && body.length >= 1) {
                const response = await axios.post(
                    url,
                    {
                        "user_id": currentUser?.id,
                        "department_programme": searchId,
                        "data": body,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                            'real-time-token-auth': currentUser?.token,
                        },
                    }
                )
                if (response.data.error === false) {
                    handleRefresh()
                    handleCloseExcelDialog()
                    setIsSubmitting(false)
                }
            } else {
                searchId === 0 && setSeverityMessage("Select department or programme")
                body.length === 0 && setSeverityMessage("Choose excel file")
                searchId === 0 && body.length === 0 && setSeverityMessage("Choose excel file and select department or programme")
                setSeverity("error")
                handleClickAlert()
                setIsSubmitting(false)
            }
        } catch (error) {
            error.response.data.message[0] && setSeverityMessage(error.response.data.message[0])
            setSeverity("error")
            handleClickAlert()
            setIsSubmitting(false)
        }
    }

    const fetchOptions = async (searchValue) => {
        try {
            const response = await axios.post(
                searchUrl,
                { "query": searchValue },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'real-time-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                setOptions(response.data.data)
            }
        } catch (error) {
            setOptions([])
        }
    }

    return (
        <div>
            <Dialog
                open={openExcelDialog}
                onClose={handleCloseExcelDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <>
                        <DialogContentText>
                            The uploaded excel file should contains the
                            column as given the screen shot below.
                        </DialogContentText>
                        <Box
                            component='img'
                            sx={{
                                width: 1,
                                mx: 'auto',
                                my: 2,
                            }}
                            alt="Excel file"
                            src={imagePath}
                        />
                        {searchUrl &&
                            <Autocomplete
                                options={options}
                                getOptionLabel={(option) =>
                                    title === "Add New Students" ?
                                        option.programme_name.toString() :
                                        option.department_name.toString()
                                }
                                filterOptions={(x) => x}
                                noOptionsText="No items"
                                includeInputInList
                                filterSelectedOptions
                                onChange={(event, value) => {
                                    setSearchId(value.id)
                                }}
                                renderOption={(props, option) => {

                                    return (
                                        <li {...props}>
                                            <List sx={{ width: "100%" }}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <BookOutlined />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            title === "Add New Students" ?
                                                                option.programme_name :
                                                                option.department_name
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </li>
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={title === "Add New Students" ?
                                            "Search programme" :
                                            "Search department"
                                        }
                                        color='secondary'
                                        fullWidth
                                        margin='normal'
                                        variant="outlined"
                                        value={value}
                                        onChange={(event) => {
                                            setValue(event.target.value)
                                            fetchOptions(event.target.value)
                                        }}
                                    />
                                )}
                            />}
                        <Button
                            sx={{ mt: 2 }}
                            component="label"
                            variant="outlined"
                            startIcon={<PhotoOutlined />}
                        >
                            Choose excel file
                            <input
                                hidden
                                accept=".xls, .xlsx"
                                type="file"
                                onChange={readUploadFile}
                            />
                        </Button>
                        {imageurl && selectedImage &&
                            (
                                <Typography
                                    color="primary"
                                >
                                    {selectedImage.name}
                                </Typography>
                            )
                        }
                    </>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseExcelDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handlesubmitExcel}
                        disabled={isSubmitting}
                    >
                        Upload file
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}