import * as React from 'react'
import { Box, CircularProgress, FormControlLabel, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popover, Switch, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography, } from '@mui/material'
import { CheckOutlined, CloudOutlined, FolderOutlined, MoreVertOutlined, ThumbUpOutlined, } from '@mui/icons-material'
import EnhancedTableHead from '../components/EnhancedTableHead'
import EnhancedTableToolbar from '../components/EnhancedTableToolbar'
import { getComparator, handleExport, handleFolderNavigation, stableSort, StyledTableCell, StyledTableRow } from '../utils/constants'
import { Link, useParams } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'

function DataGrid({
    folders,
    rows,
    isLoading,
    popoverItems,
    headCells,
    setSelected,
    label,
    searchTerm,
    handleSearch,
    from,
    currentFolder,
    setCurrentFolder,
    setType,
}) {
    const { setCurrentIndex, } = React.useContext(AuthContext)
    const { courseId, folderId } = useParams()
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('createAt')
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClickPopOver = (event, dataSelected, index) => {
        setAnchorEl(event.currentTarget)
        var newSelected = []
        newSelected.push({ ...dataSelected, index: index })
        setSelected(newSelected)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'car-update' : undefined

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: { width: '200px' }
                }}
            >
                <MenuList
                    disablePadding
                    sx={{
                        '& > *': {
                            '&:first-of-type': {
                                borderTopColor: 'divider',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px'
                            },
                            padding: '12px 16px'
                        }
                    }}
                >
                    {popoverItems.map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                item.onClick()
                                handleClose()
                            }}
                        >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                {item.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>

            <Box
                sx={{
                    width: '100%',
                    px: 3,
                    py: 2,
                }}
            >
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar
                        handleSearch={handleSearch}
                        handleExport={handleExport}
                        label={label}
                        headCells={headCells}
                        rows={rows}
                        from={from}
                        isLoading={isLoading}
                    />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={headCells}
                            />
                            <TableBody>
                                {from === "polls" && folders.map((folder, index) => (
                                    <StyledTableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        <StyledTableCell align="inherit">
                                            <FolderOutlined />
                                        </StyledTableCell>
                                        <StyledTableCell align="inherit">
                                            <Link
                                                to={`/create-polls/${courseId}/${folder.id}`}
                                                replace={true}
                                                onClick={() => handleFolderNavigation(folder.id, folder.folder_name, currentFolder, setCurrentFolder)}
                                            >
                                                {folder.folder_name}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="inherit">
                                            <Link
                                                to={`/create-polls/${courseId}/${folder.id}`}
                                                replace={true}
                                                onClick={() => handleFolderNavigation(folder.id, folder.folder_name, currentFolder, setCurrentFolder)}
                                            >
                                                {folder.updated_at}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="inherit">
                                            <Link
                                                to={`/create-polls/${courseId}/${folder.id}`}
                                                replace={true}
                                                onClick={() => handleFolderNavigation(folder.id, folder.folder_name, currentFolder, setCurrentFolder)}
                                            >
                                                {folder.count} {folder.count > 1 ? "items" : "item"}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <IconButton
                                                onClick={(event) => {
                                                    setType("folder")
                                                    handleClickPopOver(event, folder, index)
                                                }}
                                            >
                                                <MoreVertOutlined />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                {stableSort(rows, getComparator(order, orderBy))
                                    .filter(item =>
                                        Object.values(item).some(value =>
                                            value
                                                .toString()
                                                .toLowerCase()
                                                .includes(searchTerm.toLowerCase())
                                        )
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {

                                        return (
                                            <StyledTableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                {headCells.map((column, index) => {
                                                    if (column.id === 'icon') {
                                                        return (
                                                            <StyledTableCell key={index}>
                                                                {row.poll_type === "multipleChoices" && <CheckOutlined />}
                                                                {row.poll_type === "wordCloud" && <CloudOutlined />}
                                                                {row.poll_type === "questionAndAnswer" && <ThumbUpOutlined />}
                                                            </StyledTableCell>
                                                        )
                                                    } else if (column.id === 'count') {
                                                        return (
                                                            <StyledTableCell key={index}>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/activate-polls/${courseId}/${folderId}`,
                                                                    }}
                                                                    replace={true}
                                                                    onClick={() => {
                                                                        const trueIndex = rows.findIndex(item => item.id === row.id)
                                                                        setCurrentIndex(trueIndex)
                                                                    }}
                                                                >
                                                                    {row.count} {row.count > 1 ? "responses" : "response"}
                                                                </Link>
                                                            </StyledTableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <StyledTableCell key={column.id}>
                                                                {from !== "polls" && row[column.id]}
                                                                {from === "polls" && <Link
                                                                    to={{
                                                                        pathname: `/activate-polls/${courseId}/${folderId}`,
                                                                    }}
                                                                    replace={true}
                                                                    onClick={() => {
                                                                        const trueIndex = rows.findIndex(item => item.id === row.id)
                                                                        setCurrentIndex(trueIndex)
                                                                    }}
                                                                >
                                                                    {row[column.id]}
                                                                </Link>}
                                                            </StyledTableCell>
                                                        )
                                                    }
                                                })}
                                                <StyledTableCell>
                                                    <IconButton
                                                        onClick={(event) => {
                                                            setType("poll")
                                                            handleClickPopOver(event, row, index)
                                                        }}
                                                    >
                                                        <MoreVertOutlined />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {isLoading === true &&
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <CircularProgress
                                sx={{
                                    mx: 'auto',
                                    my: 3,
                                }}
                            />
                        </Box>
                    }
                    {rows.length === 0 && isLoading === false &&
                        <Typography
                            sx={{ my: 3, }}
                            align='center'
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            No items
                        </Typography>
                    }
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={'Rows per page'}
                    />
                </Paper>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label={'Dense padding'}
                />
            </Box>
        </>
    )
}

export default DataGrid