import * as React from 'react'
import uuid from 'react-uuid'
import { Box, Breadcrumbs, Button, Grid, Snackbar, Typography } from '@mui/material'
import { DeleteOutlined, EditOutlined, RemoveRedEyeOutlined, } from '@mui/icons-material'
import MuiAlert from '@mui/material/Alert'
import FormDialog from '../../components/FormDialog'
import PollsDialog from '../../components/PollsDialog'
import { pollsHeadCells } from '../../utils/tableHeaders'
import DataGrid from '../../components/DataGrid'
import { folderFields } from '../../utils/formFields'
import { handleBreadcrumbNavigation, pollsButtonItems } from '../../utils/constants'
import { addFolderUrl, deleteFolderUrl, deletePollUrl, getFolderAndPollsUrl, updateFolderUrl } from '../../utils/urls'
import { postAPIRequest } from '../../services/postAPIRequest'
import { AuthContext } from '../../contexts/AuthContext'
import axios from 'axios'
import Delete from '../../components/Delete'
import { Link, useNavigate, useParams } from 'react-router-dom'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function CreatePolls() {
  const { currentUser, } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const [item, setItem] = React.useState([])
  const [folders, setFolders] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [action, setAction] = React.useState("")
  const [type, setType] = React.useState("")
  const [selected, setSelected] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState("")
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [openAlert, setOpenAlert] = React.useState(false)
  const [severity, setSeverity] = React.useState("")
  const [severityMessage, setSeverityMessage] = React.useState("")
  const [history, setHistory] = React.useState([])
  const { courseId, folderId } = useParams()
  const [currentFolder, setCurrentFolder] = React.useState({
    name: ["default"],
    path: ["default"],
  })

  React.useEffect(() => {
    postAPIRequest(
      getFolderAndPollsUrl,
      {
        "course_code": courseId,
        "folder_id": folderId
      },
      setRows,
      setIsLoading,
      setFolders,
      "polls",
      setItem,
    )
  }, [courseId, folderId])

  React.useEffect(() => {
    const fetchResponses = async () => {
      const folders = {
        name: ["default"],
        path: ["default"],
      }
      if (currentFolder === folders) {
        navigate(`/create-polls/${courseId}/default`, { replace: true })
      }
    }

    folderId !== "default" && fetchResponses()
  }, [navigate, courseId, folderId, currentFolder])

  //Department Dialog Form
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (newAction) => {
    setAction(newAction)
    setOpen(true)
  }

  const handleClose = () => {
    setType("")
    setOpen(false)
  }

  // Poll Dialog Form
  const [openCreatePolls, setOpenCreatePolls] = React.useState(false)

  const handleClickOpenCreatePolls = (actionToPerform) => {
    setAction(actionToPerform)
    setOpenCreatePolls(true)
  }

  const handleCloseCreatePolls = () => {
    setType("")
    setOpenCreatePolls(false)
  }

  //Delete Dialog Form
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    handleRefresh()
    setOpenDeleteDialog(false)
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleRefresh = () => {
    postAPIRequest(
      getFolderAndPollsUrl,
      {
        "course_code": courseId,
        "folder_id": folderId
      },
      setRows,
      setIsLoading,
      setFolders,
      "polls",
      setItem,
    )
  }

  const handleClickAlert = () => {
    setOpenAlert(true)
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }

  const handleUndo = async () => {
    setIsSubmitting(true)
    try {
      const response = await axios.post(
        type === "folder" ? deleteFolderUrl : deletePollUrl,
        type === "folder" ? {
          "folder_id": history[0]?.id,
          "user_id": currentUser?.id
        } : {
          "poll_id": history[0]?.id,
          "user_id": currentUser?.id
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        handleRefresh()
        setIsSubmitting(false)
      }
    } catch (error) {
      setSeverityMessage(error.response.data.message[0])
      setSeverity("error")
      handleClickAlert()
      setIsSubmitting(false)
    }
  }

  const handlesubmitForm = async (folder, setSubmitting, resetForm, setErrors) => {
    setSubmitting(true)
    try {
      const response = await axios.post(
        action === "create" ? addFolderUrl : updateFolderUrl,
        action === "create" ? {
          "user_id": currentUser?.id,
          "folder_name": folder.folder_name,
          "parent_folder_id": folderId,
          "course_code": courseId,
        } : {
          ...folder,
          "folder_id": selected.length === 1 && selected[0]?.id,
          "user_id": currentUser?.id
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        handleRefresh()
        resetForm()
        handleClose()
        setSeverityMessage(response.data.message)
        setSeverity("success")
        handleClickAlert()
        setSubmitting(false)
      }
    } catch (error) {
      setErrors(error.response.data)
      error.response.data.message[0] && setSeverityMessage(error.response.data.message[0])
      setSeverity("error")
      handleClickAlert()
      setSubmitting(false)
    }
  }

  const values = [
    {
      folder_name: action === "edit" ? selected[0]?.folder_name : "",
    }
  ]

  const carRegistrationPopoverItems = type === "folder" ? [
    {
      id: 'edit',
      label: 'edit',
      icon: <EditOutlined fontSize="small" />,
      onClick: () => { handleClickOpen("edit") },
    },
    {
      id: 'delete',
      label: 'delete',
      icon: <DeleteOutlined fontSize="small" />,
      onClick: () => {
        setAction("delete")
        handleClickOpenDeleteDialog()
      },
    },
  ] : [
    {
      id: 'view',
      label: 'See responses',
      icon: <RemoveRedEyeOutlined fontSize="small" />,
      onClick: () => {
        handleClickOpenCreatePolls("see responses")
      },
    },
    {
      id: 'edit',
      label: 'edit',
      icon: <EditOutlined fontSize="small" />,
      onClick: () => { handleClickOpenCreatePolls("update") },
    },
    {
      id: 'delete',
      label: 'delete',
      icon: <DeleteOutlined fontSize="small" />,
      onClick: () => {
        setAction("delete")
        handleClickOpenDeleteDialog()
      },
    },
  ]

  return (
    <>
      {action !== "delete" &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{
              width: '100%'
            }}
          >
            {severityMessage}
          </Alert>
        </Snackbar>
      }
      {action === "delete" &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={6000}
          message={severityMessage}
          onClose={handleCloseAlert}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                handleUndo()
                handleCloseAlert()
              }}
            >
              Undo
            </Button>
          }
        />
      }
      <Grid container>
        <Grid item xs={6}>
          <Box sx={{ flexDirection: 'row', ml: 3, mt: 3 }}>
            {pollsButtonItems.map((item) => (
              <Button
                key={uuid()}
                startIcon={item.icon}
                variant={item.variant}
                size="small"
                sx={{ mr: item.margin, mb: 1 }}
                onClick={() => {
                  item.title === "Activity" ?
                    handleClickOpenCreatePolls("create") :
                    handleClickOpen("create")
                }}
                className="transition ease-in-out delay-150 hover:-translate-x-1 hover:scale-105 duration-300"
              >
                {item.title}
              </Button>
            ))}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              mr: 4,
              pt: 4,
            }}
          >
            <Typography color="primary">
              <Link
                to="/"
              >
                Home
              </Link>
            </Typography>
            {currentFolder.path.map((folderId, index) => (
              <Typography key={folderId} color="primary">
                <Link
                  to={`/create-polls/${courseId}/${folderId}`}
                  onClick={() => handleBreadcrumbNavigation(index, courseId, currentFolder, setCurrentFolder)}
                >
                  {currentFolder.name[index]}
                </Link>
              </Typography>
            ))}
            <Typography color="text.primary">
              {courseId}
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <PollsDialog
        openCreatePolls={openCreatePolls}
        handleCloseCreatePolls={handleCloseCreatePolls}
        action={action}
        selected={selected}
        setSelected={setSelected}
        handleRefresh={handleRefresh}
        folders={[...folders, { id: item.id, folder_name: item.folder_name }]}
        folderName={item.folder_name}
      />
      <FormDialog
        open={open}
        handleClose={handleClose}
        title={action === "create" ? "Create folder" : "Update folder"}
        label="folder"
        fields={folderFields}
        values={values}
        action={action}
        handlesubmitForm={handlesubmitForm}
      />
      <Delete
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        url={type === "folder" ? deleteFolderUrl : deletePollUrl}
        body={selected.length === 1 && type === "folder" ? {
          "folder_id": selected[0]?.id,
          "user_id": currentUser?.id
        } : {
          "poll_id": selected[0]?.id,
          "user_id": currentUser?.id
        }}
        selected={selected}
        setSeverity={setSeverity}
        setSeverityMessage={setSeverityMessage}
        handleClickAlert={handleClickAlert}
        setHistory={setHistory}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
      />
      <DataGrid
        folders={folders}
        rows={rows}
        isLoading={isLoading}
        popoverItems={carRegistrationPopoverItems}
        headCells={pollsHeadCells}
        setSelected={setSelected}
        label="Polls"
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        from="polls"
        currentFolder={currentFolder}
        setCurrentFolder={setCurrentFolder}
        setType={setType}
      />
    </>
  )
}