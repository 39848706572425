import React from 'react'
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material'
import { DashBoardIcon } from '../../icons/dashboard'
import { BookIcon } from '../../icons/book'
import { UserIcon } from '../../icons/user'
import { useLocation, useNavigate } from 'react-router-dom'
import NavItem from './NavItem'
import uuid from 'react-uuid'
import { admin } from '../../utils/constants'
import { AuthContext } from '../../contexts/AuthContext'

export default function SideBar({ open, onClose }) {
  const { currentUser, } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const items = currentUser.role === admin ? [
    {
      href: '/',
      icon: (<DashBoardIcon fontSize="small" />),
      title: 'Dashboard'
    },
    {
      href: '/departments',
      icon: (<BookIcon fontSize="small" />),
      title: 'Departments'
    },
    {
      href: '/programmes',
      icon: (<BookIcon fontSize="small" />),
      title: 'Programmes'
    },
    {
      href: '/courses',
      icon: (<BookIcon fontSize="small" />),
      title: 'Courses'
    },
    {
      href: '/assigned-programme-courses',
      icon: (<BookIcon fontSize="small" />),
      title: 'Programme Courses'
    },
    {
      href: '/assigned-instructor-courses',
      icon: (<BookIcon fontSize="small" />),
      title: 'Instructor Courses'
    },
    {
      href: '/students',
      icon: (<UserIcon fontSize="small" />),
      title: 'Students'
    },
    {
      href: '/instructors',
      icon: (<UserIcon fontSize="small" />),
      title: 'Instructors'
    },
    {
      href: '/adminstrators',
      icon: (<UserIcon fontSize="small" />),
      title: 'Adminstrators'
    },
  ] : [
    {
      href: '/',
      icon: (<DashBoardIcon fontSize="small" />),
      title: 'Dashboard'
    },
  ]

  const router = useLocation;

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  })

  React.useEffect(
    () => {
      if (!router.isReady) {
        return
      }

      if (open) {
        onClose?.()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath]
  )

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Box
              component='img'
              sx={{
                width: 2 / 4,
                mx: 'auto',
                my: 2,
                cursor: 'pointer',
              }}
              onClick={() => { navigate("/", { replace: true }) }}
              alt="Logo"
              src={require("../../assets/images/UDOM_LOGO.png")}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(80, 72, 229, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1
              }}
            >
              <div>
                <Typography
                  color="neutral.900"
                  variant="subtitle1"
                >
                  Real Time Active Learning Tool
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3
          }}
        />
        <Box sx={{ flexGrow: 1, }}>
          {items.map((item) => (
            <NavItem
              key={uuid()}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>
      </Box>
    </>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}