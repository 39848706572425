import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import Home from './pages/Home'
import Courses from './pages/admin/Courses'
import Students from './pages/admin/Students'
import Instructors from './pages/admin/Instructors'
import Adminstrators from './pages/admin/Adminstrators'
import Departments from './pages/admin/Departments'
import Programmes from './pages/admin/Programmes'
import AssignedProgrammeCourses from './pages/admin/AssignedProgrammeCourses'
import AssignedInstructorCourses from './pages/admin/AssignedInstructorCourses'
import CreatePolls from './pages/instructor/CreatePolls'
import ActivatePolls from './pages/instructor/ActivatePolls'
import theme from './theme'
import Layout from './components/layout/Layout'
import uuid from 'react-uuid'
import Login from './pages/Login'
import AnswerPolls from './pages/learner/AnswerPolls'
import { useContext } from "react"
import { AuthContext } from "./contexts/AuthContext"

export default function App() {
  const { currentUser } = useContext(AuthContext)

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" replace={true} />
    }

    return children
  };

  const items = [
    {
      path: '/login',
      component: <Login />,
    },
    {
      path: '/',
      component:
        <ProtectedRoute>
          <Layout>
            <Home />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'answer-polls/:courseId',
      component:
        <ProtectedRoute>
          <Layout>
            <AnswerPolls />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'create-polls/:courseId/:folderId',
      component:
        <ProtectedRoute>
          <Layout>
            <CreatePolls />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'activate-polls/:courseId/:folderId',
      component:
        <ProtectedRoute>
          <ActivatePolls />
        </ProtectedRoute>,
    },
    {
      path: 'departments',
      component:
        <ProtectedRoute>
          <Layout>
            <Departments />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'programmes',
      component:
        <ProtectedRoute>
          <Layout>
            <Programmes />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'courses',
      component:
        <ProtectedRoute>
          <Layout>
            <Courses />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'assigned-programme-courses',
      component:
        <ProtectedRoute>
          <Layout>
            <AssignedProgrammeCourses />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'assigned-instructor-courses',
      component:
        <ProtectedRoute>
          <Layout>
            <AssignedInstructorCourses />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'students',
      component:
        <ProtectedRoute>
          <Layout>
            <Students />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'instructors',
      component:
        <ProtectedRoute>
          <Layout>
            <Instructors />
          </Layout>
        </ProtectedRoute>,
    },
    {
      path: 'adminstrators',
      component:
        <ProtectedRoute>
          <Layout>
            <Adminstrators />
          </Layout>
        </ProtectedRoute>,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {
              items.map(
                (item, routeIndex) => (
                  routeIndex === 1 ?
                    <Route
                      key={uuid()}
                      index
                      element={item.component}
                    /> : <Route
                      key={uuid()}
                      path={item.path}
                      element={item.component}
                    />
                )
              )
            }
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}