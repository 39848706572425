import React from "react"

export const AuthContext = React.createContext()

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = React.useState(() => {
        const userString = localStorage.getItem('carRentingWebUser')
        const storedUser = JSON.parse(userString)
        return storedUser ?? null
    })
    const [currentIndex, setCurrentIndex] = React.useState(0)
    // const value = React.useMemo(() => ({ currentUser, setCurrentUser }), [currentUser, setCurrentUser])

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                setCurrentUser,
                currentIndex,
                setCurrentIndex,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
