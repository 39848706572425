export const pollsHeadCells = [
  {
    id: 'icon',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'poll',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified',
  },
  {
    id: 'count',
    numeric: false,
    disablePadding: false,
    label: 'Count',
  },
]

export const departmentHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Department ID',
  },
  {
    id: 'department_name',
    numeric: false,
    disablePadding: false,
    label: 'Department Name',
  },
  {
    id: 'head_of_department',
    numeric: false,
    disablePadding: false,
    label: 'Head Of Department',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Modified At',
  },
]

export const programmeHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Programme ID',
  },
  {
    id: 'programme_name',
    numeric: false,
    disablePadding: false,
    label: 'Programme Name',
  },
  {
    id: 'department_name',
    numeric: false,
    disablePadding: false,
    label: 'Department Name',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Modified At',
  },
]

export const courseHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Course ID',
  },
  {
    id: 'course_code',
    numeric: false,
    disablePadding: false,
    label: 'Course Code',
  },
  {
    id: 'course_name',
    numeric: false,
    disablePadding: false,
    label: 'Course Name',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Modified At',
  },
]

export const assignedProgrammeCoursesHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Assigned ID',
  },
  {
    id: 'programme_name',
    numeric: false,
    disablePadding: false,
    label: 'Programme',
  },
  {
    id: 'course_code',
    numeric: false,
    disablePadding: false,
    label: 'Course Code',
  },
  {
    id: 'course_name',
    numeric: false,
    disablePadding: false,
    label: 'Course Name',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Modified At',
  },
]

export const assignedInstructorCoursesHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Assigned ID',
  },
  {
    id: 'full_name',
    numeric: false,
    disablePadding: false,
    label: 'Instrucor Name',
  },
  {
    id: 'course_code',
    numeric: false,
    disablePadding: false,
    label: 'Course Code',
  },
  {
    id: 'course_name',
    numeric: false,
    disablePadding: false,
    label: 'Course Name',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Modified At',
  },
]

export const studentHeadCells = [
  {
    id: 'full_name',
    numeric: false,
    disablePadding: true,
    label: 'Student Name',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Registration Number',
  },
  {
    id: 'department_name',
    numeric: false,
    disablePadding: false,
    label: 'Student Department',
  },
  {
    id: 'programme_name',
    numeric: false,
    disablePadding: false,
    label: 'Student Programme',
  },
  {
    id: 'gender',
    numeric: false,
    disablePadding: false,
    label: 'Gender',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Modified At',
  },
]

export const staffHeadCells = [
  {
    id: 'full_name',
    numeric: false,
    disablePadding: true,
    label: 'Staff Name',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Staff Number',
  },
  {
    id: 'department_name',
    numeric: false,
    disablePadding: false,
    label: 'Staff Department',
  },
  {
    id: 'gender',
    numeric: false,
    disablePadding: false,
    label: 'Gender',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Modified At',
  },
]

export const seeResponsesHeadCells = [
  {
    id: 'response',
    numeric: false,
    disablePadding: false,
    label: 'Response',
  },
  {
    id: 'user_id',
    numeric: false,
    disablePadding: false,
    label: 'Response by',
  },
  {
    id: 'correct_answer',
    numeric: false,
    disablePadding: false,
    label: 'Correct answer',
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Received at',
  },
]