import * as React from 'react'
import uuid from 'react-uuid'
import { Box, Button, Snackbar } from '@mui/material'
import { DeleteOutlined, } from '@mui/icons-material'
import MuiAlert from '@mui/material/Alert'
import FormDialog from '../../components/FormDialog'
import ExcelDialogForm from '../../components/ExcelDialogForm'
import { staffHeadCells } from '../../utils/tableHeaders'
import DataGrid from '../../components/DataGrid'
import { staffFields } from '../../utils/formFields'
import { actionButtons, instructor, body } from '../../utils/constants'
import { addUserUrl, deleteUserUrl, getAllUserUrl, searchDepartmentUrl, updateDepartmentUrl } from '../../utils/urls'
import { postAPIRequest } from '../../services/postAPIRequest'
import { AuthContext } from '../../contexts/AuthContext'
import axios from 'axios'
import Delete from '../../components/Delete'
import userScreenShot from '../../assets/images/user.png'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function Instructors() {
  const { currentUser, } = React.useContext(AuthContext)
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [action, setAction] = React.useState("")
  const [selected, setSelected] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState("")
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [openAlert, setOpenAlert] = React.useState(false)
  const [severity, setSeverity] = React.useState("")
  const [severityMessage, setSeverityMessage] = React.useState("")
  const [history, setHistory] = React.useState([])

  React.useEffect(() => {
    postAPIRequest(getAllUserUrl, { ...body, role: instructor }, setRows, setIsLoading)
  }, [])

  //Department Dialog Form
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (newAction) => {
    setAction(newAction)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // Excel Dialog Form
  const [openExcelDialog, setOpenExcelDialog] = React.useState(false)

  const handleClickOpenExcelDialog = () => {
    setOpenExcelDialog(true)
  }

  const handleCloseExcelDialog = () => {
    setOpenExcelDialog(false)
  }

  //Delete Dialog Form
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    handleRefresh()
    setOpenDeleteDialog(false)
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleRefresh = () => {
    postAPIRequest(getAllUserUrl, { ...body, role: instructor }, setRows, setIsLoading)
  }

  const handleClickAlert = () => {
    setOpenAlert(true)
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }

  const handleUndo = async () => {
    setIsSubmitting(true)
    try {
      const response = await axios.post(
        deleteUserUrl,
        { "department_id": history[0]?.id, "user_id": currentUser?.id },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        handleRefresh()
        setIsSubmitting(false)
      }
    } catch (error) {
      setSeverityMessage(error.response.data.message[0])
      setSeverity("error")
      handleClickAlert()
      setIsSubmitting(false)
    }
  }

  const handlesubmitForm = async (instructors, setSubmitting, resetForm, setErrors) => {
    setSubmitting(true)
    try {
      const response = await axios.post(
        action === "create" ? addUserUrl : updateDepartmentUrl,
        action === "create" ? {
          "user_id": currentUser?.id,
          "department_programme": instructors.department_name,
          "data": [{ ...instructors, role: instructor }],
        } : { ...instructors, "id": selected.length === 1 && selected[0]?.id, "user_id": currentUser?.id },
        {
          headers: {
            'Content-Type': 'application/json',
            'real-time-sign-auth': `${process.env.REACT_APP_KEY}`,
            'real-time-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        handleRefresh()
        resetForm()
        handleClose()
        setSeverityMessage(response.data.message)
        setSeverity("success")
        handleClickAlert()
        setSubmitting(false)
      }
    } catch (error) {
      setErrors(error.response.data)
      error.response.data.message[0] && setSeverityMessage(error.response.data.message[0])
      setSeverity("error")
      handleClickAlert()
      setSubmitting(false)
    }
  }

  const values = [
    {
      full_name: action === "edit" ? selected[0]?.full_name : "",
      identity_number: action === "edit" ? selected[0]?.identity_number : "",
      gender: action === "edit" ? selected[0]?.gender : "MALE",
      password: action === "edit" ? selected[0]?.password : "",
      department_name: action === "edit" ? selected[0]?.department_name : "",
    }
  ]

  const carRegistrationPopoverItems = [
    // {
    //   id: 'viewCar',
    //   label: 'view',
    //   icon: <RemoveRedEyeOutlined fontSize="small" />,
    //   onClick: () => {
    //     setAction("view")
    //     handleClickOpenFormDialog()
    //   },
    // },
    // {
    //   id: 'editCar',
    //   label: 'edit',
    //   icon: <EditOutlined fontSize="small" />,
    //   onClick: () => {
    //     handleClickOpen("edit")
    //   },
    // },
    {
      id: 'deleteCar',
      label: 'delete',
      icon: <DeleteOutlined fontSize="small" />,
      onClick: () => {
        setAction("delete")
        handleClickOpenDeleteDialog()
      },
    },
  ]

  return (
    <>
      {action !== "delete" &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{
              width: '100%'
            }}
          >
            {severityMessage}
          </Alert>
        </Snackbar>
      }
      {action === "delete" &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={6000}
          message={severityMessage}
          onClose={handleCloseAlert}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                handleUndo()
                handleCloseAlert()
              }}
            >
              Undo
            </Button>
          }
        />
      }
      <Box sx={{ flexDirection: 'row', ml: 3, mt: 3 }}>
        {actionButtons.map((item) => (
          <Button
            key={uuid()}
            startIcon={item.icon}
            variant="outlined"
            size="small"
            sx={{ mr: item.margin, mb: 1 }}
            onClick={() => {
              item.title === 'Add' ?
                handleClickOpen("create") :
                handleClickOpenExcelDialog()
            }}
          >
            {item.title}
          </Button>
        ))}
      </Box>
      <ExcelDialogForm
        openExcelDialog={openExcelDialog}
        handleCloseExcelDialog={handleCloseExcelDialog}
        title={"Add New Instructor"}
        url={addUserUrl}
        handleRefresh={handleRefresh}
        searchUrl={searchDepartmentUrl}
        setSeverityMessage={setSeverityMessage}
        setSeverity={setSeverity}
        handleClickAlert={handleClickAlert}
        imagePath={userScreenShot}
      />
      <FormDialog
        open={open}
        handleClose={handleClose}
        title="Add New Instructor"
        label="instructor"
        fields={staffFields}
        values={values}
        action={action}
        handlesubmitForm={handlesubmitForm}
      />
      <Delete
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        url={deleteUserUrl}
        body={selected.length === 1 && { "user_id": selected[0]?.id, "adimin_id": currentUser?.id }}
        selected={selected}
        setSeverity={setSeverity}
        setSeverityMessage={setSeverityMessage}
        handleClickAlert={handleClickAlert}
        setHistory={setHistory}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
      />
      <DataGrid
        rows={rows}
        isLoading={isLoading}
        popoverItems={carRegistrationPopoverItems}
        headCells={staffHeadCells}
        setSelected={setSelected}
        label="Instructors"
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        from="instructors"
      />
    </>
  )
}