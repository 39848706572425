import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { AppBar, Container, IconButton, Toolbar, } from '@mui/material'
import { Close, } from '@mui/icons-material'
import TabPanel from './TabPanel'
import BuildPolls from './BuildPolls'
import SeeResponses from './SeeResponses'

export default function PollsDialog({
    openCreatePolls,
    handleCloseCreatePolls,
    action,
    selected,
    setSelected,
    handleRefresh,
    folders,
}) {
    return (
        <Dialog
            open={openCreatePolls}
            onClose={handleCloseCreatePolls}
            fullScreen
            PaperProps={{
                style: {
                    backgroundColor: "#E5E7EB",
                    boxShadow: "none"
                },
            }}
        >
            <DialogContent
                color='background'
            >
                <AppBar
                    sx={{
                        position: 'relative',
                        backgroundColor: '#E5E7EB',
                    }}
                    color='background'
                    elevation={0}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseCreatePolls}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Container
                    maxWidth="md"
                >
                    {action === "create" &&
                        <TabPanel
                            action={action}
                            selected={selected}
                            setSelected={setSelected}
                            handleRefresh={handleRefresh}
                            handleCloseCreatePolls={handleCloseCreatePolls}
                            folders={folders}
                        />
                    }
                    {action === "update" &&
                        <BuildPolls
                            pollType={selected[0]?.poll_type}
                            action={action}
                            selected={selected}
                            setSelected={setSelected}
                            handleRefresh={handleRefresh}
                            handleCloseCreatePolls={handleCloseCreatePolls}
                            folders={folders}
                        />
                    }
                </Container>
                <Container
                    maxWidth="xl"
                >
                    {action === "see responses" &&
                        <SeeResponses
                            selected={selected}
                        />
                    }
                </Container>
            </DialogContent>
        </Dialog>
    )
}
