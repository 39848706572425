// export const baseUrl = "http://127.0.0.1:4000/api/v1"
// export const publicBaseUrl = "http://127.0.0.1:4000"
// export const socketUrl = "ws://localhost:4000/ws"

export const baseUrl = "https://activeleaning.api.majorcorecode.co.tz/api/v1"
export const publicBaseUrl = "https://activeleaning.api.majorcorecode.co.tz"
export const socketUrl = "wss://activeleaning.api.majorcorecode.co.tz/ws"

export const searchDepartmentUrl = `${baseUrl}/search/departments`
export const addDepartmentUrl = `${baseUrl}/create/department`
export const getAllDepartmentUrl = `${baseUrl}/get/departments`
export const updateDepartmentUrl = `${baseUrl}/update/department`
export const deleteDepartmentUrl = `${baseUrl}/delete/department`
export const searchProgrammeUrl = `${baseUrl}/search/programmes`
export const addProgrammeUrl = `${baseUrl}/create/programme`
export const getAllProgrammeUrl = `${baseUrl}/get/programmes`
export const updateProgrammeUrl = `${baseUrl}/update/programme`
export const deleteProgrammeUrl = `${baseUrl}/delete/programme`
export const searchCourseUrl = `${baseUrl}/search/courses`
export const addCourseUrl = `${baseUrl}/create/course`
export const getAllCourseUrl = `${baseUrl}/get/courses`
export const updateCourseUrl = `${baseUrl}/update/course`
export const deleteCourseUrl = `${baseUrl}/delete/course`
export const addAssignCourseInstructorUrl = `${baseUrl}/create/assign/course/to/instructor`
export const getAllAssignedCourseInstructorUrl = `${baseUrl}/get/assigned/courses/instructors`
export const updateAssignedCourseInstructorUrl = `${baseUrl}/update/assign/course/to/instructor`
export const deleteAssignedCourseInstructorUrl = `${baseUrl}/delete/assign/course/to/instructor`
export const addAssignCourseProgrammeUrl = `${baseUrl}/create/assign/course/to/programme`
export const getAllAssignedCourseProgrammeUrl = `${baseUrl}/get/assigned/courses/programmes`
export const updateAssignedCourseProgrammeUrl = `${baseUrl}/update/assign/course/to/programme`
export const deleteAssignedCourseProgrammeUrl = `${baseUrl}/delete/assign/course/to/programme`
export const getCoursesForSpecificInstructorUrl = `${baseUrl}/get/courses/for/specific/instructor`
export const getCoursesForSpecificStudentUrl = `${baseUrl}/get/courses/for/specific/student`
export const getAdminDashboardStatisticsUrl = `${baseUrl}/get/admin/dashboard/statistics`
export const searchUserUrl = `${baseUrl}/search/users`
export const addUserUrl = `${baseUrl}/create/user`
export const getAllUserUrl = `${baseUrl}/get/users`
export const deleteUserUrl = `${baseUrl}/delete/user`
export const addOrRemoveTaskForSpecificUserUrl = `${baseUrl}/add/remove/task/for/specific/user`
export const loginUrl = `${baseUrl}/login/user`
export const changePasswordUrl = `${baseUrl}/change/password`
export const addFolderUrl = `${baseUrl}/create/folder`
export const updateFolderUrl = `${baseUrl}/update/folder`
export const deleteFolderUrl = `${baseUrl}/delete/folder`
export const getFolderAndPollsUrl = `${baseUrl}/gets/folders/and/polls/for/specific/course/and/folder`
export const getActivePollUrl = `${baseUrl}/get/active/poll`
export const getSpecificPollResponseUrl = `${baseUrl}/get/specific/poll/response`
export const getSpecificPollResponseHistoryUrl = `${baseUrl}/get/specific/poll/response/history`
export const activatePollUrl = `${baseUrl}/activate/poll`
export const deactivatePollUrl = `${baseUrl}/deactivate/poll`
export const addPollUrl = `${baseUrl}/create/poll`
export const addPollImageUrl = `${baseUrl}/add/poll/image`
export const updatePollUrl = `${baseUrl}/update/poll`
export const deletePollUrl = `${baseUrl}/delete/poll`
export const clearPollResponseUrl = `${baseUrl}/clear/response`
export const addPollResponseUrl = `${baseUrl}/add/response`
export const likeDislikePollResponseUrl = `${baseUrl}/like/dislike/response`
export const deletePollResponseUrl = `${baseUrl}/delete/response`